import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const ItemLabel = styled.span`
  color: #373737;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
`;

export const RedItemLabel = styled(ItemLabel)`
  color: ${PrimaryColors.red};
`;
