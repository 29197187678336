export * from "./h1";
export * from "./h2";
export * from "./h3";
export * from "./s1";
export * from "./s2";
export * from "./p1";
export * from "./p2";
export * from "./label";

export const defaultFontFamily = "Nexa";
