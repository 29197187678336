import React from "react";
import styled from "styled-components";

import { AddComponent } from "@genesys/ui-elements/lib/icons";

const DivNewCaseContainer = styled.div`
  box-sizing: border-box;
  height: 466px;
  width: 224px;
  border: 1px dashed #8f9bb3;
  border-radius: 9px;
  background-color: #f7f9fc;
`;

const AddComponentImg = styled(AddComponent)`
  height: 50px;
  width: 50px;
  margin: auto;
  display: block;
  margin-top: 8.31px;
`;

const NewCaseLabel = styled.p`
  color: #373737;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-top: 40px;
`;

const InnerNewContainer = styled.div`
  height: 125.24px;
  width: 131px;
  margin: auto;
  margin-top: 157px;
  vertical-align: middle;
  cursor: pointer;
`;

type Props = {
  readonly onClick: () => void;
  readonly label: string;
};

export function NewOperatingCaseCard(props: Props) {
  return (
    <DivNewCaseContainer>
      <InnerNewContainer onClick={props.onClick}>
        <AddComponentImg />
        <NewCaseLabel>{props.label}</NewCaseLabel>
      </InnerNewContainer>
    </DivNewCaseContainer>
  );
}
