import * as React from "react";
import styled from "styled-components";
import * as SharedState from "../../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import { prepareRows, groupScreenRows } from "../shared-tools";
import { PropertyValueSet } from "@genesys/property";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";

interface ScreenRow {
  readonly id: string;
  readonly groupName: string | undefined;
  readonly name: string | undefined;
  readonly claimFilter: string | undefined | null;
  readonly propertyFilter: string | undefined | null;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const GridRow = styled.div<{
  readonly hasBlueBackground: boolean;
  readonly isHidden: boolean | undefined;
}>`
  /* color: #959695; */
  background-color: ${props => props.hasBlueBackground && "#f7f9fc"};
  ${props => props.isHidden && "border: 1px solid red;"}
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  font-size: 13px; ;
`;

const Label = styled.div`
  letter-spacing: 0;
  line-height: 17px;
`;

const HeaderLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  padding-left: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
`;
export function PerParams({
  validRows,
  sharedState,
  selectedProperties,
  caseResult,
  productId,
  dispatch,
  conversionParametersMap
}: {
  readonly validRows: ScreenRow[];
  readonly sharedState: SharedState.State;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly caseResult: PropertyValueSet.PropertyValueSet;
  readonly productId: string;
  readonly dispatch: Dispatch<Action>;
  readonly conversionParametersMap: {
    readonly [key: string]: QuantityConversion.ConversionParameters | undefined;
  };
}) {
  if (validRows.length === 0) {
    return null;
  }

  const groupedRows = groupScreenRows(validRows);

  return (
    <Container>
      {Array.from(groupedRows.entries()).map(([groupName, rows]) => {
        const newRows = prepareRows(
          rows,
          productId,
          sharedState,
          {
            type: "single",
            result: caseResult,
            conversionParametersMap: conversionParametersMap
          },
          dispatch,
          selectedProperties,
          sharedState.user.applicationClaims
        );

        const hideGroupName =
          (newRows.find(r => !r.isHidden) === undefined &&
            !sharedState.debugSettings.showHiddenResults) ||
          newRows.length === 0;

        return [
          <HeaderLabel key={groupName}>
            {hideGroupName
              ? ""
              : sharedState.translate(
                  LanguageTexts.globalPropertyName(groupName)
                )}
          </HeaderLabel>,
          <div key={""}></div>,
          newRows.map((r, i) => [
            <GridRow
              isHidden={r.isHidden}
              key={r.name}
              hasBlueBackground={i % 2 === 0}
            >
              <Label>{r.name}</Label>
              {""}
              {r.amountFormatSelector && r.amountFormatSelector()}
            </GridRow>,
            ...r.results.map((res, j) => [
              <GridRow isHidden={false} hasBlueBackground={i % 2 === 0} key={j}>
                <Label>{res}</Label>
              </GridRow>
            ])
          ])
        ];
      })}
    </Container>
  );
}
