import * as React from "react";
import { isDesktop } from "react-device-detect";
import { Dispatch } from "@typescript-tea/core";
import {
  MenuButton,
  MainLogoYellow,
  MainLogo,
  MainLogoGreen,
  MainLogoPurple,
  HelpIcon,
  ProfileIcon,
  WithMenu,
  SettingsIcon,
  ToolTipWrapper,
  OpenBlue
} from "@genesys/ui-elements";
import {
  MenuBarContainer,
  StyledA,
  DropDownContainer,
  TopOuter,
  DropDownMenu,
  FixedContainer,
  FlexItemContainer,
  StyledUl
} from "./elements";
import { State, Action } from "./state";
import { clientConfig, Environment } from "../config";
import { UserManager } from "oidc-client";
import { hasPermissionForMlc } from "../moisture-load-calculation";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../shared-state";
import * as Routes from "../routes";

interface LinkMenuItem {
  readonly type: "link";
  readonly openInNewTab?: boolean;
  readonly label: string;
  readonly href: string;
  readonly isVisable: boolean;
}

interface DropDownMenuItem {
  readonly type: "dropdown";
  readonly label: string;
  readonly isVisable: boolean;
  readonly isOpen: boolean;
  readonly toggleDropdown: () => void;
  readonly menuItems: ReadonlyArray<{
    readonly label: string;
    readonly href: string;
    readonly isVisable: boolean;
  }>;
}

interface CustomMenuItem {
  readonly type: "custom";
  readonly content: JSX.Element;
  readonly isVisable: boolean;
}

type MenuItem = {
  readonly isVisable: boolean;
  readonly routes: ReadonlyArray<Routes.MainLocation["type"]>;
  // readonly label: string
} & (LinkMenuItem | DropDownMenuItem | CustomMenuItem);

// type MenuItem = LinkMenuItem | DropDownMenuItem | CustomMenuItem;

export const MainMenuView = ({
  dispatch,
  logoClicked,
  state,
  sharedState,
  activeRoute
}: {
  readonly dispatch: Dispatch<Action>;
  readonly logoClicked: () => void;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly activeRoute: Routes.MainLocation["type"];
}) => {
  const userClaims = sharedState.user.applicationClaims;

  // Permissions
  const canUsePricing = Authorization.checkPermission(
    userClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );
  const canSeePricing = Authorization.checkPermission(
    userClaims,
    Authorization.genesysPricingClaims.canSeePricing
  );
  const canSeeMoistureload = hasPermissionForMlc(userClaims);
  const canSeeSystemSelectionGuide =
    (Authorization.checkPermission(
      userClaims,
      Authorization.genesysUserClaims.developer
    ) ||
      Authorization.checkPermission(
        userClaims,
        Authorization.genesysUserClaims.canUseSystemSelectionGuide
      )) &&
    isDesktop;
  const canSeeMRC =
    Authorization.checkPermission(
      userClaims,
      Authorization.genesysUserClaims.canSeeMRC
    ) && isDesktop;

  const enviroment = clientConfig.environment;
  const [borderColor, genesysLogo] = getEnviromentVariables(enviroment);

  // Helper to render a dropdown with a list of tools
  const renderMenuItemWithDropdown = (
    menuName: string,
    isOpen: boolean,
    toggleDropdown: () => void,
    menuItems: ReadonlyArray<{ readonly label: string; readonly href: string }>,
    routes: ReadonlyArray<Routes.MainLocation["type"]>
  ) => (
    <DropDownContainer
      onMouseEnter={toggleDropdown}
      onMouseLeave={toggleDropdown}
    >
      <MenuButton
        children={menuName}
        hasMenu={true}
        isOpen={isOpen}
        isActive={routes.includes(activeRoute)}
      />
      {isOpen && (
        <DropDownMenu onClick={toggleDropdown}>
          {menuItems.map(item => (
            <a key={item.href} href={item.href}>
              <MenuButton
                children={capitalizeFirstLetter(item.label)}
                hasMenu={false}
              />
            </a>
          ))}
        </DropDownMenu>
      )}
    </DropDownContainer>
  );

  const renderMenus = (menuItems: ReadonlyArray<MenuItem>) => {
    return (
      <StyledUl>
        {menuItems
          .filter(x => x.isVisable)
          .map(item => {
            if (item.type === "link") {
              return (
                <a href={item.href} target={item.openInNewTab ? "_blank" : ""}>
                  <MenuButton
                    children={capitalizeFirstLetter(item.label)}
                    hasMenu={false}
                    isActive={item.routes.includes(activeRoute)}
                  />
                </a>
              );
            } else if (item.type === "custom") {
              return item.content;
            }
            return renderMenuItemWithDropdown(
              item.label,
              item.isOpen,
              item.toggleDropdown,
              item.menuItems,
              item.routes
            );
          })}
      </StyledUl>
    );
  };

  const header = renderMenus([
    {
      type: "custom",
      isVisable: true,
      routes: ["Dashboard"],
      content: (
        <li>
          <div
            onClick={e => {
              e.stopPropagation();
              logoClicked();
            }}
          >
            {genesysLogo}
          </div>
        </li>
      )
    }
  ]);

  const systemManager = renderMenus([
    {
      type: "link",
      label: sharedState.translate(LanguageTexts.systems()),
      href: "/system-manager",
      isVisable: true,
      routes: ["SystemManager", "SystemConfiguration"]
    }
  ]);

  const menuItems = renderMenus([
    {
      type: "dropdown",
      label: sharedState.translate(LanguageTexts.tools()),
      isOpen: state.isToolsDropdownOpen,
      toggleDropdown: () => dispatch(Action.toggleToolsDrowdown()),
      isVisable: true,
      routes: ["Tools"],
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.airMixer()),
          href: "/tools/air-mixing",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.airPowerTool()),
          href: "/tools/air-power",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.humidityConversion()),
          href: "/tools/humidity-conversion",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.psychrometricChart()),
          href: "/tools/psychrometric-chart",
          isVisable: true
        },
        {
          label: sharedState.translate(LanguageTexts.binPage()),
          href: "/tools/weather-data-binning",
          isVisable: true
        }
      ]
    },
    {
      type: "dropdown",
      label: sharedState.translate(LanguageTexts.pricing()),
      isOpen: state.isPricingDropdownOpen,
      toggleDropdown: () => dispatch(Action.togglePricingDrowdown()),
      isVisable: canUsePricing || canSeePricing,
      routes: ["PricingEditor", "PricingManager", "PricingWizard"],
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.pricingmanager()),
          href: "/pricing-manager",
          isVisable: true
        },

        {
          label: sharedState.translate(LanguageTexts.newPricing()),
          href: "/new-pricing",
          isVisable: canUsePricing
        }
      ]
    },

    {
      type: "dropdown",
      label: sharedState.translate(LanguageTexts.moistureLoad()),
      isOpen: state.isMoistureLoadDropdownOpen,
      toggleDropdown: () => dispatch(Action.toggleMoistureLoadDropdown()),
      isVisable: canSeeMoistureload,
      routes: ["MoistureLoadCalc", "MoistureLoadManager", "MoistureLoadWizard"],
      menuItems: [
        {
          label: sharedState.translate(LanguageTexts.moistureLoadManager()),
          href: "/moisture-load-manager",
          isVisable: canSeeMoistureload
        },
        {
          label: sharedState.translate(LanguageTexts.newMoistureLoadCalc()),
          href: "/new-moisture-load",
          isVisable: canSeeMoistureload
        }
      ]
    },

    {
      type: "link",
      label: "MRC",
      href: "https://mrc.genesys.munters.com/",
      isVisable: canSeeMRC,
      routes: [],
      openInNewTab: true
    },
    {
      type: "link",
      label: "Selection Guide",
      href: "/system-selection-guide",
      routes: ["SystemSelectionGuide"],
      isVisable: canSeeSystemSelectionGuide
    }
  ]);

  const newSystem = renderMenus([
    {
      type: "custom",
      isVisable: true,
      routes: [],
      content: (
        <ToolTipWrapper
          padding="0px"
          title={sharedState.translate(LanguageTexts.newsystem())}
        >
          <StyledA href="/new-system">
            <OpenBlue height={"28px"} />
          </StyledA>
        </ToolTipWrapper>
      )
    }
  ]);

  return (
    <TopOuter>
      <FixedContainer>
        <MenuBarContainer>
          {header}
          <FlexItemContainer>
            <>
              {systemManager} {menuItems}
            </>
          </FlexItemContainer>
          <FlexItemContainer>{newSystem}</FlexItemContainer>
          {/* User Menu and Settings */}

          <ul
            style={{
              display: "flex",
              alignItems: "center",
              listStyleType: "none",
              margin: 0,
              padding: 0
            }}
          >
            {/* Help Menu */}
            <li>
              <WithMenu
                icon={<HelpIcon width="20px" />}
                values={[
                  {
                    value: sharedState.translate(LanguageTexts.wiki()),
                    onClick: () =>
                      window.open("https://wiki.genesys.munters.com/")
                  },
                  {
                    value: sharedState.translate(LanguageTexts.changeLog()),
                    onClick: () =>
                      window.open(
                        `${clientConfig.wikiJsBaseUrl.replace(
                          "/graphql",
                          "/Changelogs"
                        )}`
                      )
                  },
                  {
                    value: sharedState.translate(LanguageTexts.about()),
                    onClick: e =>
                      e.ctrlKey
                        ? window.open("/about")
                        : dispatch(Action.navigate("/about"))
                  }
                ]}
              />
            </li>

            {/* User Settings */}
            <li>
              <ToolTipWrapper
                padding="0px"
                title={sharedState.translate(LanguageTexts.usersettings())}
              >
                <StyledA href="/user-settings">
                  <SettingsIcon height={"23px"} />
                </StyledA>
              </ToolTipWrapper>
            </li>

            {/* Profile Menu */}
            <li>
              <WithMenu
                icon={<ProfileIcon width="20px" />}
                values={[
                  {
                    value: `${sharedState.translate(
                      LanguageTexts.loggedinuser()
                    )}: ${sharedState.user.userName}`,
                    onClick: () => undefined
                  },
                  {
                    value: sharedState.translate(
                      LanguageTexts.requestAccessLevel()
                    ),
                    onClick: () => window.open("https://stsusers.munters.com/")
                  },
                  {
                    value: sharedState.translate(LanguageTexts.logout()),
                    onClick: async () => {
                      const manager = new UserManager({
                        authority: clientConfig.oidcAuthority,
                        client_id: clientConfig.oidcClientId,
                        redirect_uri: clientConfig.oidcRedirectUri,
                        post_logout_redirect_uri: window.location.origin,
                        response_type: clientConfig.oidcResponseType,
                        scope: clientConfig.oidcScope,
                        monitorSession: false,
                        loadUserInfo: false
                      });
                      await manager.signoutRedirect();
                    }
                  }
                ]}
              />
            </li>
          </ul>
        </MenuBarContainer>

        {/* Environment Border */}
        {enviroment !== "Production" && (
          <div style={{ backgroundColor: borderColor, height: "8px" }} />
        )}
      </FixedContainer>
    </TopOuter>
  );
};

function getEnviromentVariables(env: Environment): [string, JSX.Element] {
  switch (env) {
    case "Localhost":
      return ["#A020F0", <MainLogoPurple width="106px" />];
    case "Develop": {
      return ["#46D580", <MainLogoGreen width="106px" />];
    }
    case "Staging": {
      return ["#F0C530", <MainLogoYellow width="106px" />];
    }

    case "Production": {
      return ["", <MainLogo width="106px" />];
    }

    default: {
      return exhaustiveCheck(env);
    }
  }
}

function capitalizeFirstLetter(str: string) {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
