import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as ComponentOrder from "@genesys/shared/lib/component-order-2";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemPerformance from "@genesys/client-core/lib/system-performance";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import {
  P1,
  H2,
  OperatingCasesSelect,
  WeatherIcon
} from "@genesys/ui-elements";
import { AmountFormatSelector } from "../../amount-format-selector";
import * as SharedState from "../../shared-state";
import * as Product from "../product";
import {
  AirPositionTh,
  HeadingContainer,
  DropDownContainer,
  OperatingCasesContainer,
  OptionTable,
  PerformanceTable,
  ResultTd,
  Container,
  StyledTh,
  TablesContainer,
  Root
} from "./elements";
import * as State from "./state";
import {
  PerformanceOverviewSystemItem,
  PerformanceOverviewSystemType
} from "./types";
import {
  getScreenAirPropertiesResultsAndFilteredAirPositions,
  getConversionParameters
} from "./functions";

export type Props = {
  readonly systemItem: PerformanceOverviewSystemItem;
  readonly systemType: PerformanceOverviewSystemType;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly sharedState: SharedState.State;
  readonly state: State.State;
  readonly selectedAirPosition: string | undefined;
  readonly onAirPositionClick: (airPosition: string | undefined) => void;
  readonly dispatch: Dispatch<State.Action>;
};

export function PerformanceOverviewView({
  selectedAirPosition,
  sharedState,
  state,
  sysProperties,
  systemItem,
  systemType,
  onAirPositionClick,
  dispatch
}: Props): JSX.Element {
  if (systemItem.status < SystemStatus.DesignCalculationSuccess) {
    return <></>;
  }

  const connectionPoints = ProductData.getValidProductsForRange(
    systemType.products,
    sysProperties
  ).reduce(
    (soFar: ReadonlyArray<Product.BoxConnectionPoint>, product) =>
      soFar.concat(product.boxConnectionPoints),
    []
  );

  const screenAirProperties = SystemPerformance.toScreenAirProperty(
    systemType.systemType.airPointProperties.filter(ap =>
      PropertyFilterHelpers.isValid(
        ap.propertyFilter,
        PropertyFilter.Empty,
        sysProperties
      )
    )
  );

  const airPositions = ComponentOrder.getAirPositions(
    "UnitConfiguration",
    systemItem.components,
    systemItem.airstreams,
    connectionPoints.map(cp => ({
      connectionType: cp.connectionType,
      hideStatePoint: cp.hideStatePoint,
      diagramType: cp.diagramType,
      productSectionId: cp.productSectionId,
      propertyFilter: cp.propertyFilter,
      id: cp.id
    })),
    false
  );

  const opCases = SystemPerformance.toOpCases(
    sharedState.translate,
    systemItem.operatingCases
  );

  const selectedOpCaseId =
    state.selectedOpCaseId &&
    opCases.find(opc => opc.id === state.selectedOpCaseId)
      ? state.selectedOpCaseId
      : opCases[0].id;

  const selectedOpCase = opCases.find(opc => opc.id === selectedOpCaseId);

  const fieldGroup = `AirLocationTable.${systemType.systemType.id}`;

  const [screenAirPropertiesResult, effectiveAirPositions] =
    getScreenAirPropertiesResultsAndFilteredAirPositions(
      screenAirProperties,
      airPositions,
      selectedOpCase,
      sharedState.screenAmounts.getAmountFormat,
      fieldGroup
    );

  const conversionParameters = getConversionParameters(
    screenAirPropertiesResult
  );

  const selectedAirPositionIndex = selectedAirPosition
    ? effectiveAirPositions.find(
        ap => selectedAirPosition === String.fromCharCode(ap.label)
      )!.label - 65
    : undefined;

  return (
    <Root>
      <Container>
        <HeadingContainer>
          <H2 color="primary" weight="bold">
            {sharedState.translate(LanguageTexts.airLocations())}
          </H2>
        </HeadingContainer>

        <DropDownContainer>
          <OperatingCasesContainer>
            <H2 color="dark" weight="normal">
              <WeatherIcon />
              {sharedState.translate(LanguageTexts.operatingCases())}
            </H2>

            <OperatingCasesSelect
              value={selectedOpCaseId}
              options={opCases.map(op => ({ value: op.id, title: op.name }))}
              onChange={e => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(State.Action.SelectOpCase(e.target.value));
              }}
            />
          </OperatingCasesContainer>

          <TablesContainer>
            <OptionTable>
              <thead>
                <tr>
                  <StyledTh>
                    <hr />
                  </StyledTh>
                </tr>
              </thead>

              <tbody>
                {screenAirPropertiesResult.map(sap => (
                  <tr key={sap.name}>
                    <td>
                      {sap.amountFormat && (
                        <AmountFormatSelector
                          type="AmountFormatSelectorProps"
                          fieldName={sap.name}
                          fieldGroup={fieldGroup}
                          amountFormat={sap.amountFormat}
                          conversionParameters={
                            sap.name === "Flow" || sap.name === "Humidity"
                              ? conversionParameters
                              : undefined
                          }
                          translate={sharedState.translate}
                          onFormatCleared={() =>
                            dispatch(
                              State.Action.FormatCleared(fieldGroup, sap.name)
                            )
                          }
                          onFormatChanged={(unit, decimalCount) =>
                            dispatch(
                              State.Action.FormatChanged(
                                fieldGroup,
                                sap.name,
                                unit,
                                decimalCount
                              )
                            )
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </OptionTable>

            <PerformanceTable>
              <thead>
                <tr>
                  {effectiveAirPositions.map((ap, i) => (
                    <AirPositionTh
                      key={`${ap.airstreamId}-${ap.label}`}
                      isSelected={
                        selectedAirPosition === String.fromCharCode(ap.label)
                      }
                      onClick={() =>
                        onAirPositionClick(
                          String.fromCharCode(ap.label) === selectedAirPosition
                            ? undefined
                            : String.fromCharCode(ap.label)
                        )
                      }
                    >
                      <hr />
                      <P1 weight="bold"> {String.fromCharCode(ap.label)}</P1>
                      {i < effectiveAirPositions.length - 1 ? (
                        <hr />
                      ) : (
                        <span
                          style={{
                            visibility: "hidden"
                          }}
                        ></span>
                      )}
                    </AirPositionTh>
                  ))}
                </tr>
              </thead>

              <tbody>
                {screenAirPropertiesResult.map(sap => {
                  const results = sap.result;
                  return (
                    <tr key={sap.name}>
                      {results.map((result, i) => (
                        <ResultTd
                          key={`${result}-${i}`}
                          isSelected={selectedAirPositionIndex === i}
                        >
                          <P1 weight="normal" color="secondary">
                            {result}
                          </P1>
                        </ResultTd>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </PerformanceTable>
          </TablesContainer>
        </DropDownContainer>
      </Container>
    </Root>
  );
}
