// tslint:disable
import {
  createArrowDownSvg,
  createArrowUpSvg,
  createArrowLeftSvg,
  createArrowRightSvg,
  createHomeIconSvg,
  createSystemManagerIconSvg,
  createOpenIconSvg,
  createHelpIconSvg,
  createSettingsSvgIcon,
  createProfileSvg
} from "./src/embedded-svg-creators";
import { wrap } from "./icon-wrapper";
import { PrimaryColors } from "../colors";

export * from "./system-configurator-icons";

// Action
export const ExportBlue = wrap(
  require("ui-elements/assets/action/export-blue.svg")
);
export const ExportDisabled = wrap(
  require("ui-elements/assets/action/export-disabled.svg")
);
export const Export = wrap(require("ui-elements/assets/action/export.svg"));
export const LCCCompare = wrap(
  require("ui-elements/assets/action/lcc-compare.svg")
);
export const LCCCompareDisabled = wrap(
  require("ui-elements/assets/action/lcc-compare-disabled.svg")
);
export const LCCCompareBlue = wrap(
  require("ui-elements/assets/action/lcc-compare-blue.svg")
);
export const LabelSelection = wrap(
  require("ui-elements/assets/action/project-selection.svg")
);
export const LabelSelectionBlue = wrap(
  require("ui-elements/assets/action/project-selection-blue.svg")
);
export const LabelSelectionDisabled = wrap(
  require("ui-elements/assets/action/project-selection-disabled.svg")
);
export const Summary = wrap(require("ui-elements/assets/action/summary.svg"));
export const SummaryBlue = wrap(
  require("ui-elements/assets/action/summary-blue.svg")
);
export const SummaryDisabled = wrap(
  require("ui-elements/assets/action/summary-disabled.svg")
);
export const Trash = wrap(require("ui-elements/assets/action/trash.svg"));
export const TrashDisabled = wrap(
  require("ui-elements/assets/action/trash-disabled.svg")
);
export const TrashBlue = wrap(
  require("ui-elements/assets/action/trash-blue.svg")
);

export const Location = wrap(require("ui-elements/assets/action/location.svg"));

// Button

const defaultColor = PrimaryColors.blue400;
const hoverColor = PrimaryColors.blue500;
const activeColor = PrimaryColors.blue600;

export const ArrowDown = wrap(
  createArrowDownSvg(defaultColor),
  createArrowDownSvg(hoverColor),
  createArrowDownSvg(activeColor)
);

export const ArrowUp = wrap(
  createArrowUpSvg(defaultColor),
  createArrowUpSvg(hoverColor),
  createArrowUpSvg(activeColor)
);

export const ArrowRight = wrap(
  createArrowRightSvg(defaultColor),
  createArrowRightSvg(hoverColor),
  createArrowRightSvg(activeColor)
);

export const ArrowLeft = wrap(
  createArrowLeftSvg(defaultColor),
  createArrowLeftSvg(hoverColor),
  createArrowLeftSvg(activeColor)
);

export const Open = wrap(
  createOpenIconSvg(defaultColor),
  createOpenIconSvg(hoverColor),
  createOpenIconSvg(activeColor)
);

export const ArrowDownBlue = wrap(createArrowDownSvg(defaultColor));

export const ArrowUpBlue = wrap(createArrowUpSvg(defaultColor));
export const ArrowDownGreen = wrap(
  require("ui-elements/assets/button/arrow-down-green.svg")
);

export const ArrowDownNew = wrap(
  require("ui-elements/assets/button/arrow-down-new.svg")
);

export const ArrowUpNew = wrap(
  require("ui-elements/assets/button/arrow-up-new.svg")
);

export const ArrowRightBlue = wrap(createArrowRightSvg(defaultColor));

export const ArrowLeftBlue = wrap(createArrowLeftSvg(defaultColor));

export const ArrowDownWhite = wrap(
  require("ui-elements/assets/button/arrow-down-white.svg")
);
export const OpenBlue = wrap(
  require("ui-elements/assets/button/open-blue.svg")
);
export const OpenGreen = wrap(
  require("ui-elements/assets/button/open-green.svg")
);

export const ArrowRightLongWhite = wrap(
  require("ui-elements/assets/button/arrow-right-long-white.svg")
);

export const ArrowRightLongBlue = wrap(
  require("ui-elements/assets/button/arrow-right-long-blue.svg")
);

// Dashboard
export const Next = wrap(require("ui-elements/assets/dashboard/next.svg"));
export const Previous = wrap(
  require("ui-elements/assets/dashboard/previous.svg")
);

export const HomeIcon = wrap(
  createHomeIconSvg(defaultColor),
  createHomeIconSvg(hoverColor),
  createHomeIconSvg(activeColor)
);

export const HomeHoverIcon = wrap(
  require("ui-elements/assets/main-menu/home-hover.svg")
);

export const HomeSelectedIcon = wrap(
  require("ui-elements/assets/main-menu/home-selected.svg")
);

export const SystemsIcon = wrap(
  createSystemManagerIconSvg(defaultColor),
  createSystemManagerIconSvg(hoverColor),
  createSystemManagerIconSvg(activeColor)
);
export const HelpIcon = wrap(
  createHelpIconSvg(defaultColor),
  createHelpIconSvg(hoverColor),
  createHelpIconSvg(activeColor)
);

export const SettingsIcon = wrap(
  createSettingsSvgIcon(defaultColor),
  createSettingsSvgIcon(hoverColor),
  createSettingsSvgIcon(activeColor)
);

export const ProfileIcon = wrap(
  createProfileSvg(defaultColor),
  createProfileSvg(hoverColor),
  createProfileSvg(activeColor)
);

export const SystemsHoverIcon = wrap(
  require("ui-elements/assets/main-menu/systems-hover.svg")
);
export const SystemsSelectedIcon = wrap(
  require("ui-elements/assets/main-menu/systems-selected.svg")
);

export const MainLogo = wrap(require("ui-elements/assets/logos/main-logo.svg"));
export const MainLogoYellow = wrap(
  require("ui-elements/assets/logos/main-logo-yellow.svg")
);
export const MainLogoGreen = wrap(
  require("ui-elements/assets/logos/main-logo-green.svg")
);
export const MainLogoPurple = wrap(
  require("ui-elements/assets/logos/main-logo-purple.svg")
);
export const HelpGrayIcon = wrap(
  require("ui-elements/assets/main-menu/help-gray.svg")
);

//spinners
export const Spinner = wrap(
  require("ui-elements/assets/spinners/animated-spinner.svg")
);

// System manager

export const SearchIcon = wrap(
  require("ui-elements/assets/system-manager/search-icon.svg")
);
export const ClearIcon = wrap(
  require("ui-elements/assets/system-manager/clear.svg")
);

export const TableIcon = wrap(
  require("ui-elements/assets/system-manager/table-icon.svg")
);

export const ListIcon = wrap(
  require("ui-elements/assets/system-manager/list-icon.svg")
);

export const GridIcon = wrap(
  require("ui-elements/assets/system-manager/grid-icon.svg")
);

// system card
export const FavoriteActive = wrap(
  require("ui-elements/assets/system-card/active.svg")
);

export const FavoriteInactive = wrap(
  require("ui-elements/assets/system-card/inactive.svg")
);

// Warning
export const InfoWhite = wrap(
  require("ui-elements/assets/alert-accordion/info.svg")
);

export const InfoGray = wrap(
  require("ui-elements/assets/alert-accordion/info-grej.svg")
);

export const InfoBlue = wrap(
  require("ui-elements/assets/alert-accordion/info-blue.svg")
);

// MoistureLoad
export const HeatLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/heat-load-icon.png")
);

export const MoistureLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/moisture-load-icon.png")
);

export const EmissionFromCombustionIcon = wrap(
  require("ui-elements/assets/moisture-load/emission-from-combustion.svg")
);

export const EmissionFromPeopleIcon = wrap(
  require("ui-elements/assets/moisture-load/emission-from-people.svg")
);

export const EvaporationFromMaterialsIcon = wrap(
  require("ui-elements/assets/moisture-load/evaporation-from-materials.svg")
);

export const EvaporationOfWaterIcon = wrap(
  require("ui-elements/assets/moisture-load/evaporation-of-water.svg")
);

export const HeatTransmissionIcon = wrap(
  require("ui-elements/assets/moisture-load/heat-transmission.svg")
);

export const IntenitionalVentilationIcon = wrap(
  require("ui-elements/assets/moisture-load/Intentional-ventilation.svg")
);

export const StaticHeatLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/static-heat-load.svg")
);

export const StaticMoistureLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/static-moisture-load.svg")
);

export const UnintendedVentilationIcon = wrap(
  require("ui-elements/assets/moisture-load/unintended-ventilation.svg")
);

export const OpenDoorIcon = wrap(
  require("ui-elements/assets/moisture-load/open-door.svg")
);
// System-selection-guide

export const WarningIcon = wrap(
  require("ui-elements/assets/system-selection-guide/warning.svg")
);

// Logs
export const LogsIcon = wrap(
  require("ui-elements/assets/logs/log-file-icon.svg")
);

export const WeatherIcon = wrap(
  require("ui-elements/assets/system-performance-overview/weather.svg")
);

export const RedCrossIcon = wrap(
  require("ui-elements/assets/system-configurator/red-cross.svg")
);
