import * as React from "react";
import * as SharedState from "../../../../../shared-state";
import * as GraphQlTypes from "../../../../../graphql-types";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import { getValue } from "@genesys/shared/lib/product-properties";
import { groupScreenRows } from "../shared-tools";
import { Amount, Quantity } from "@genesys/uom";
import { AmountFormatSelector } from "../../../../../amount-format-selector";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import styled from "styled-components";
import { clientConfig } from "../../../../../config";
import { Img } from "@genesys/ui-elements";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const GridRow = styled.div<{
  readonly hasBlueBackground: boolean;
  readonly isHidden: boolean | undefined;
}>`
  color: #959695;
  background-color: ${props => props.hasBlueBackground && "#f7f9fc"};
  ${props => props.isHidden && "border: 1px solid red;"}
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  font-size: 13px; ;
`;

const HeaderLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  padding-left: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const Label = styled.div`
  letter-spacing: 0;
  line-height: 17px;
`;

const ImgContainer = styled.div`
  padding: 24px;
`;

interface ScreenRow {
  readonly id?: string;
  readonly groupName: string | undefined;
  readonly name: string | undefined;
  readonly claimFilter: string | undefined | null;
  readonly propertyFilter: string | undefined | null;
}

export function View({
  sharedState,
  componentSpec,
  validRows,
  systemType,
  images,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly validRows: ScreenRow[];
  readonly componentSpec: PropertyValueSet.PropertyValueSet;
  readonly systemType: string;
  readonly images: ReadonlyArray<
    GraphQlTypes.ResultSummmaryQuery["product"]["product"]["images"][0]
  >;
  readonly dispatch: Dispatch<Action>;
}) {
  const groupedRows = groupScreenRows(validRows);

  const image = SpecificationImage({
    images: images,
    sharedState: sharedState
  });

  return (
    <Container>
      {image !== null && image}
      {Array.from(groupedRows.entries()).map(([groupName, rows]) => {
        const newRows = prepareRows(
          rows,
          componentSpec,
          sharedState,
          systemType,
          dispatch
        );

        return [
          <HeaderLabel key={groupName}>
            {groupName &&
              sharedState.translate(
                LanguageTexts.dynamicText(groupName, systemType)
              )}
          </HeaderLabel>,
          <div key={""}></div>,
          newRows.map((r, i) => [
            <GridRow
              isHidden={false}
              key={r.name}
              hasBlueBackground={i % 2 === 0}
            >
              <Label>{r.name}</Label>
              {r.amountFormatSelector && r.amountFormatSelector()}
            </GridRow>,
            ...r.results.map((res, j) => [
              <GridRow isHidden={false} hasBlueBackground={i % 2 === 0} key={j}>
                <Label>{res}</Label>
              </GridRow>
            ])
          ])
        ];
      })}
    </Container>
  );
}

export function getValidRows(
  screenSpecifications: GraphQlTypes.ResultSummmaryQuery["product"]["product"]["screen_Specifications"],
  sharedState: SharedState.State,
  selectedProperties: PropertyValueSet.PropertyValueSet
) {
  const applicationClaims = sharedState.user.applicationClaims;

  return (screenSpecifications?.rows || [])
    .map(r => {
      const values = PropertyValueSet.fromStringOrError(
        () => PropertyValueSet.Empty,
        r.values
      );
      const row: ScreenRow = {
        id: r.id,
        groupName: PropertyValueSet.getText("GroupName", values),
        name: PropertyValueSet.getText("Name", values),
        claimFilter: PropertyValueSet.getText("ClaimFilter", values),
        propertyFilter: r.propertyFilter
      };
      return row;
    })
    .filter(
      r =>
        sharedState.debugSettings.showHiddenResults ||
        (PropertyFilterHelpers.isValid(
          r.propertyFilter,
          PropertyFilter.Empty,
          selectedProperties
        ) &&
          Authorization.checkClaimFilter(
            applicationClaims,
            PropertyFilterHelpers.createPropertyFilter(
              r.claimFilter,
              PropertyFilter.Empty
            )
          ))
    );
}

function prepareRows(
  rows: ReadonlyArray<ScreenRow>,
  componentSpec: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State,
  systemType: string,
  dispatch: Dispatch<Action>
) {
  const fieldGroup = `Specifications.${systemType}`;
  const finalRows: Array<{
    readonly name: string;
    readonly results: string[];
    readonly amountFormatSelector: () => JSX.Element | null;
  }> = [];
  for (const sr of rows) {
    const resultKey = sr.name || "";
    const propertyValue = PropertyValueSet.get(resultKey, componentSpec);
    let result: string = "";

    const amountFormat =
      propertyValue?.type === "amount"
        ? sharedState.screenAmounts.getAmountFormat(
            fieldGroup,
            resultKey,
            propertyValue?.value as Amount.Amount<Quantity.Quantity>
          )
        : undefined;

    let amountFormatSelector: () => JSX.Element | null = () => {
      if (amountFormat === undefined) {
        return null;
      }
      return (
        <>
          [
          <AmountFormatSelector
            type="AmountFormatSelectorProps"
            translate={sharedState.translate}
            conversionParameters={undefined}
            fieldGroup={fieldGroup}
            fieldName={resultKey}
            amountFormat={amountFormat}
            onFormatCleared={() =>
              dispatch(Action.onFormatCleared(fieldGroup, resultKey))
            }
            onFormatChanged={(unit, decimalCount) =>
              dispatch(
                Action.onFormatChanged(
                  fieldGroup,
                  resultKey,
                  unit,
                  decimalCount
                )
              )
            }
          />
          ]
        </>
      );
    };

    if (!propertyValue) {
      continue;
    }

    if (amountFormat) {
      result = getValue(propertyValue, amountFormat);
    } else if (propertyValue.type === "text") {
      result = PropertyValue.getText(propertyValue) || "";
    } else if (propertyValue.type === "integer") {
      result = PropertyValue.getInteger(propertyValue)?.toString() || "";
    }

    const finalResults: Array<string> = [];

    finalResults.push(result);
    const row: {
      readonly name: string;
      readonly results: string[];
      readonly amountFormatSelector: () => JSX.Element | null;
    } = {
      name: sharedState.translate(
        LanguageTexts.specProp(resultKey, systemType)
      ),
      results: finalResults,
      amountFormatSelector
    };
    finalRows.push(row);
  }

  return finalRows;
}

function SpecificationImage(props: {
  readonly images: ReadonlyArray<
    GraphQlTypes.ResultSummmaryQuery["product"]["product"]["images"][0]
  >;
  readonly sharedState: SharedState.State;
}) {
  const routeEndpoint = clientConfig.genesysBackend;
  const image = props.images.find(
    i => i.imageUsage === GraphQlTypes.ImageUsage.SPECIFICATIONS
  );

  if (!image) {
    return null;
  }

  return (
    <ImgContainer>
      <Img
        height={"250px"}
        accessToken={props.sharedState.accessToken}
        src={
          routeEndpoint +
          image.url +
          `${
            image.imageFormat === GraphQlTypes.ImageFormat.SVG
              ? "?useFixedSize=true"
              : ""
          }`
        }
      />
    </ImgContainer>
  );
}
