import * as React from "react";
import styled from "../../styled-components";

const StyledSquarePattern = styled.div`
  width: 100%;
  height: 100%;
  background: #373737;
  background-size: 10px 10px;
  background-image: linear-gradient(to right, #4a4a4a 1px, transparent 1px),
    linear-gradient(to bottom, #4a4a4a 1px, transparent 1px);
`;

export function SquarePattern(
  props: React.HtmlHTMLAttributes<HTMLDivElement>
): JSX.Element {
  return <StyledSquarePattern {...props} />;
}
