import * as React from "react";
import styled from "../../styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { zIndex } from "../../zIndex";
import { PrimaryColors } from "../../colors";

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.FullOverlay};

  .MuiCircularProgress-colorSecondary {
    color: ${PrimaryColors.green};
  }
  .MuiCircularProgress-colorPrimary {
    color: #f0f0f0;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4px);
`;

const Label = styled.label`
  font-size: 18px;
  color: black;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
`;

const Test = styled.div`
  position: relative;
`;

export function CircularProgressLoader({
  progress,
  label
}: {
  readonly progress?: number;
  readonly label?: string;
}) {
  return (
    <Container>
      <StyledDiv />
      <Test>
        <CircularProgress
          variant="determinate"
          color="primary"
          size={"108px"}
          thickness={4.2}
          value={100}
        />
        <Box left={0} top={0} position="absolute">
          <CircularProgress
            variant={progress !== undefined ? "determinate" : "indeterminate"}
            value={progress}
            thickness={4.2}
            size={"108px"}
            color="secondary"
          />
        </Box>

        <Box
          top={130}
          left={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Label>{label}</Label>
        </Box>
      </Test>
    </Container>
  );
}
