import React from "react";
import styled from "../../../styled-components";

const Container = styled.div`
  display: inline-block;
`;

const BoxContainer = styled.div<{
  readonly width: string;
  readonly height: string;
}>`
  box-sizing: border-box;
  height: ${props => props.height};
  width: ${props => props.width};
  border: 1px solid #f1f5fa;
  border-radius: 9px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;

    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #959695;

    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 15px;
  row-gap: 7px;
  column-gap: 10px;
`;

const StyledTitle = styled.label`
  color: rgb(143, 155, 179);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 17px;
`;

const StyledValue = styled.label`
  color: #959695;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
`;

const Title = styled.label`
  color: #00b1f7;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 7px;
`;

type RenderAmountFormatSelector = () => JSX.Element | null;

export function TableCard({
  rows,
  title,
  width,
  height
}: {
  readonly title: string;
  readonly width?: string;
  readonly height?: string;
  readonly rows: ReadonlyArray<{
    readonly title: string;
    readonly value: string;
    readonly amountFormatSelector?: RenderAmountFormatSelector;
  }>;
}) {
  return (
    <Container>
      <Title>{title}</Title>
      <BoxContainer width={width || "100%"} height={height || "188px"}>
        <GridContainer>
          {rows.map((row, i) => [
            <StyledTitle key={row.title}>{row.title}</StyledTitle>,
            <StyledValue key={i}>
              {row.value}{" "}
              {row.amountFormatSelector && row.amountFormatSelector()}
            </StyledValue>
          ])}
        </GridContainer>
      </BoxContainer>
    </Container>
  );
}
