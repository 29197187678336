import { encodeSvgStringToImgSrc } from "../../../svg-string-img-encoder";
import { SystemConfiguratorIconProps } from "./types";

export function createSolidContactsSvg({
  color,
  backgroundColor,
  borderColor
}: SystemConfiguratorIconProps): string {
  const svgString = `

<svg width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>95D6BCD8-A79D-484E-B457-BFE89E313CB2</title>
    <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="${backgroundColor}" offset="0%"></stop>
            <stop stop-color="${backgroundColor}" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="60" height="60" rx="9"></rect>
        <filter x="-12.5%" y="-9.2%" width="125.0%" height="125.0%" filterUnits="objectBoundingBox" id="filter-3">
            <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
            <feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0268247378 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="UI-Kit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="UI-Kit---5.Icons-&amp;-Illustrations" transform="translate(-804.000000, -1786.000000)">
            <g id="Icons/System-Menu-Items/Electrical-Options" transform="translate(809.000000, 1789.000000)">
                <g id="Rectangle-Copy-14">
                    <use fill="black" fill-opacity="1" filter="url(#filter-3)" xlink:href="#path-2"></use>
                    <use stroke="${borderColor}" stroke-width="1" fill="url(#linearGradient-1)" fill-rule="evenodd" xlink:href="#path-2"></use>
                </g>
                <path d="M30,14.1597294 L44.6064065,21.5386155 L44.6064065,42.4613845 L30,49.8402706 L15.3935935,42.4613845 L15.3935935,21.5386155 L30,14.1597294 Z M16.8925935,37.2177294 L16.893,41.538 L30,48.159 L43.106,41.538 L43.1055935,37.2187294 L30,43.8402706 L16.8925935,37.2177294 Z M30,15.84 L16.893,22.461 L16.893,35.538 L30,42.159 L43.106,35.538 L43.106,22.461 L30,15.84 Z M30,22.25 C34.2567507,22.25 37.75,25.2442137 37.75,29 C37.75,32.7557863 34.2567507,35.75 30,35.75 C25.7432493,35.75 22.25,32.7557863 22.25,29 C22.25,25.2442137 25.7432493,22.25 30,22.25 Z M30,29.75 C27.9198434,29.75 26.0269446,30.6118635 24.8739033,31.9989888 C25.9995102,33.3548142 27.8679311,34.25 30,34.25 C32.1320689,34.25 34.0004898,33.3548142 35.1262789,31.9997409 C33.9772504,30.6164786 32.0851804,29.75 30,29.75 Z M30,23.75 C26.5247642,23.75 23.75,26.1283693 23.75,29 C23.75,29.5757205 23.8615301,30.1316146 24.0678749,30.6524914 C25.5208433,29.1485347 27.6750083,28.25 30,28.25 C32.3294101,28.25 34.4829855,29.1524869 35.9316364,30.6516998 C36.1384699,30.1316146 36.25,29.5757205 36.25,29 C36.25,26.1283693 33.4752358,23.75 30,23.75 Z" id="Shape" fill="${color}" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>

`;

  return encodeSvgStringToImgSrc(svgString);
}
