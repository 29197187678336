import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as CustomDescriptionBox from "../../../components/custom-description-box";
import * as SharedState from "../../../../shared-state";
import styled from "styled-components";
import {
  MoistureLoadIcon,
  HeatLoadIcon,
  ToolTipWrapper,
  AlertAccordion
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action, Load } from "../state";

const Container = styled.div`
  padding: 10px 10px 0 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLabel = styled.label`
  /* color: rgb(0, 177, 247); */
  margin-left: 5px;
  /* padding-right: 5px; */
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  /* margin-bottom: 15px; */
`;

const LoadTypeAddedContainer = styled.div`
  height: 8px;
  width: 0px;
  border-radius: 10px;
  position: absolute;
  top: -3px;
  right: -13px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPropertyValueDesc = styled.span`
  color: #959695;
  font-size: 13px;
  font-weight: 500;
`;

const StyledMoistureLoadIcon = styled(MoistureLoadIcon)<{
  readonly fade: boolean;
}>`
  opacity: ${props => (props.fade ? 0.3 : 1)};
  padding: 6px;
`;

const StyledHeatIcon = styled(HeatLoadIcon)<{
  readonly fade: boolean;
}>`
  padding: 6px;
  opacity: ${props => (props.fade ? 0.3 : 1)};
`;

export function Header({
  isDisabled,
  sharedState,
  load,
  messages,
  warningType,
  dispatch
}: {
  readonly isDisabled: boolean;
  readonly messages: ReadonlyArray<string>;
  readonly sharedState: SharedState.State;
  readonly load: Load;
  readonly warningType: "warning" | "error" | "none";
  readonly dispatch: Dispatch<Action>;
}) {
  const title = sharedState.translate(
    LanguageTexts.globalPropertyName(load.title)
  );

  return (
    <>
      <MessagesSection warningType={warningType} messages={messages} />
      <Container>
        <StyledDiv>
          <CustomDescriptionBox.View title={title}>
            <StyledPropertyValueDesc>
              {sharedState.translate(
                LanguageTexts.globalPropertyName(load.description)
              )}
            </StyledPropertyValueDesc>
          </CustomDescriptionBox.View>
          <LoadTitle
            heatAdded={!!load.heatAdded}
            moistureAdded={!!load.moistureAdded}
            title={title}
          />
        </StyledDiv>
        <StyledDiv>
          {load.properties.moistureProperty &&
            load.claims.canUseMoisture &&
            !isDisabled && (
              <ToolTipWrapper
                padding="0px"
                title={getMoistureLoadText(!!load.moistureAdded)}
                onClick={() => undefined}
              >
                <StyledMoistureLoadIcon
                  fade={!load.moistureAdded}
                  onClick={() => {
                    if (load.moistureAdded) {
                      return dispatch(
                        Action.removeTypesFromLoad(load.sourceName, "moisture")
                      );
                    }
                    dispatch(Action.addTypeToLoad(load.sourceName, "moisture"));
                  }}
                />
              </ToolTipWrapper>
            )}

          {load.properties.heatProperty &&
            load.claims.canUseHeat &&
            !isDisabled && (
              <ToolTipWrapper
                padding="0px"
                title={getHeatLoadText(!!load.heatAdded)}
                onClick={() => undefined}
              >
                <StyledHeatIcon
                  fade={!load.heatAdded}
                  onClick={() => {
                    if (load.heatAdded) {
                      return dispatch(
                        Action.removeTypesFromLoad(load.sourceName, "heat")
                      );
                    }
                    return dispatch(
                      Action.addTypeToLoad(load.sourceName, "heat")
                    );
                  }}
                />
              </ToolTipWrapper>
            )}
        </StyledDiv>
      </Container>
    </>
  );
}

function LoadTitle({
  title,
  moistureAdded,
  heatAdded
}: {
  readonly title: string;
  readonly moistureAdded: boolean;
  readonly heatAdded: boolean;
}) {
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        maxWidth: "233px"
      }}
    >
      <StyledLabel>{title.toUpperCase()} </StyledLabel>
      {
        <LoadTypeAddedContainer>
          {heatAdded && <HeatLoadIcon width={"14px"} />}
          {moistureAdded && <MoistureLoadIcon width={"14px"} />}
        </LoadTypeAddedContainer>
      }
    </div>
  );
}

function getHeatLoadText(heatAdded: boolean) {
  if (heatAdded) {
    return "Deselect Heat";
  }
  return "Add Heat";
}

function getMoistureLoadText(moistureAdded: boolean) {
  if (moistureAdded) {
    return "Deselect Moisture";
  }
  return "Add Moisture";
}

function MessagesSection({
  messages,
  warningType
}: {
  readonly messages: ReadonlyArray<string>;
  readonly warningType: "warning" | "error" | "none";
}) {
  if (!messages.length || warningType === "none") {
    return null;
  }
  return (
    <AlertAccordion
      width="100%"
      alerts={messages.map(m => ({
        title: "",
        body: m
      }))}
      title="Load Messages"
      warningType={warningType}
    />
  );
}
