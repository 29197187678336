import * as React from "react";
import * as UserSettings from "../user-settings";
import * as SharedState from "../shared-state";
import * as MainMenu from "../main-menu";
import * as Dashboard from "../dashboard";
import * as SystemConfigurator from "../system-configurator";
import * as Wizard from "../wizard";
import * as SystemManager from "../system-manager";
import * as PricingManager from "../pricing-manager";
import * as PricingWizard from "../pricing-wizard";
import * as PricingEditor from "../pricing-editor";
import * as Tools from "../tools";
import * as About from "../about";
import * as MoistureLoadManager from "../moisture-load-manager";
import * as MoistureLoadCalc from "../moisture-load-calculation";
import * as MoistureLoadWizard from "../moisture-load-wizard";
import * as DebugSettings from "../debug-settings";
import * as SystemSelectionGuide from "../system-selection-guide";
import * as DrySize from "../dry-size";
import * as LogViewer from "../log-viewer";
import styled from "styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";

const RootContainer = styled.div`
  margin: 0 auto;
`;

const FixWidthContent = styled.div`
  width: 1440px;
  margin: 0 auto;
`;

const MaxWidthContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const WizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 970px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

export function MainView({
  dispatch,
  state,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  return (
    <RootContainer>
      {state.debugSettingsState && (
        <DebugSettings.DebugSettingsView
          dispatch={Dispatch.map(Action.dispatchDebugSettings, dispatch)}
          onClose={() => {
            dispatch(Action.closeDebugSettings());
          }}
          sharedState={sharedState}
          state={state.debugSettingsState}
        />
      )}
      <MainMenu.MainMenuView
        dispatch={Dispatch.map(Action.dispatchMainMenu, dispatch)}
        logoClicked={() => {
          if (!DebugSettings.canSeeDebugSettings(sharedState)) {
            return;
          }
          dispatch(Action.logoClicked());
        }}
        sharedState={sharedState}
        state={state.mainMenuState}
        activeRoute={state.urlMatch?.location.type || "Dashboard"}
      />
      <Content dispatch={dispatch} sharedState={sharedState} state={state} />
    </RootContainer>
  );
}

function Content({
  dispatch,
  sharedState,
  state
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element | null {
  if (state.urlMatch === undefined) {
    return null;
  }

  switch (state.urlMatch.location.type) {
    case "Dashboard": {
      return (
        <MaxWidthContent>
          <Dashboard.DashboardView
            sharedState={sharedState}
            state={state.dashboardState!}
            dispatch={Dispatch.map(Action.dispatchDashboard, dispatch)}
          />
        </MaxWidthContent>
      );
    }
    case "UserSettings": {
      return (
        <MaxWidthContent>
          <UserSettings.UserSettingsView
            dispatch={Dispatch.map(Action.dispatchUserSettings, dispatch)}
            sharedState={sharedState}
            state={state.userSettingsState!}
          />
        </MaxWidthContent>
      );
    }
    case "SystemManager": {
      return (
        <MaxWidthContent>
          <SystemManager.SystemManagerView
            state={state.systemManagerState!}
            sharedState={sharedState}
            dispatch={Dispatch.map(Action.dispatchSystemManager, dispatch)}
          />
        </MaxWidthContent>
      );
    }
    case "SystemConfiguration": {
      return (
        <SystemConfigurator.View
          state={state.systemConfiguratorState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchSystemConfigurator, dispatch)}
        />
      );
    }
    case "WizardWithMoistureLoad":
    case "Wizard":
      return (
        <Wizard.WizardView
          state={state.wizardState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchWizard, dispatch)}
        />
      );
    case "PricingManager":
      return (
        <PricingManager.PricingManagerView
          state={state.pricingManagerState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPricingManager, dispatch)}
        />
      );
    case "PricingEditor":
      return (
        <PricingEditor.PricingEditorView
          state={state.pricingEditorState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPricingEditor, dispatch)}
        />
      );
    case "PricingWizard":
      return (
        <WizardContainer>
          <PricingWizard.PricingWizardView
            state={state.pricingWizardState!}
            sharedState={sharedState}
            dispatch={Dispatch.map(Action.dispatchPricingWizard, dispatch)}
          />
        </WizardContainer>
      );
    case "Tools":
      return (
        <MaxWidthContent>
          <Tools.ToolsView
            state={state.toolsState!}
            sharedState={sharedState}
            dispatch={Dispatch.map(Action.dispatchTools, dispatch)}
          />
        </MaxWidthContent>
      );
    case "About":
      return (
        <About.AboutView
          state={state.aboutState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchAbout, dispatch)}
        />
      );
    case "MoistureLoadCalc":
      return (
        <FixWidthContent>
          <MoistureLoadCalc.View
            state={state.moistureLoadCalcState!}
            sharedState={sharedState}
            dispatch={Dispatch.map(Action.dispatchMoistureLoadCalc, dispatch)}
          />
        </FixWidthContent>
      );
    case "MoistureLoadManager":
      return (
        <FixWidthContent>
          <MoistureLoadManager.View
            state={state.moistureLoadManagerState!}
            sharedState={sharedState}
            dispatch={Dispatch.map(
              Action.dispatchMoistureLoadManager,
              dispatch
            )}
          />
        </FixWidthContent>
      );
    case "MoistureLoadWizard":
      return (
        <WizardContainer>
          <MoistureLoadWizard.View
            state={state.moistureLoadWizardState!}
            sharedState={sharedState}
            dispatch={Dispatch.map(Action.dispatchMoistureLoadWizard, dispatch)}
          />
        </WizardContainer>
      );
    case "SystemSelectionGuideWithMoistureLoad":
    case "SystemSelectionGuide":
      return (
        <SystemSelectionGuide.SystemSelectionGuideView
          state={state.systemSelectionGuideState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchSystemSelectionGuide, dispatch)}
        />
      );
    case "LogViewer":
      if (!state.logViewerState) {
        return <div>Permission denied</div>;
      }
      return (
        <LogViewer.View
          state={state.logViewerState}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchLogViewer, dispatch)}
        />
      );
    case "DrySize":
      return (
        <DrySize.DrySizeView
          state={state.drySizeState!}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchDrySize, dispatch)}
        />
      );
    default: {
      return exhaustiveCheck(state.urlMatch.location, true);
    }
  }
}
