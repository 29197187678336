import * as React from "react";
import styled from "styled-components";
import { DataRow, PricingRow, PricingRowCell, EditCell } from "../../types";
import * as SharedState from "../../../shared-state";
import * as CellCreator from "./cell-creator";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Units, Serialize, Format, UnitsFormat } from "@genesys/uom";
import { P2, Trash } from "@genesys/ui-elements";

interface PricingTableProps {
  readonly sharedState: SharedState.State;
  readonly pricingRows: ReadonlyArray<PricingRow>;
  readonly locked: boolean;
  readonly editCell: EditCell | undefined;
  readonly genesysNoPrefix: string;
  readonly currencyCode: string;
  readonly onEditBegin: (cell: EditCell) => void;
  readonly onEditEnd: () => void;
  readonly onCellChange: (value: string) => void;
  readonly onDelete: (rowId: string) => void;
}

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

// const StyledDiv = styled.div`
//   max-width: 300px;
// `;

const Table = styled.table`
  td,
  th {
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;

    /* p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    } */
  }

  th {
    height: 30px;
    text-align: left;
    h5 {
      display: inline;
      img {
        margin-left: 8px;
      }
    }
  }

  td {
    height: 40px;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      cursor: pointer;
    }
  }

  tbody:nth-child(2n + 2) {
    background-color: #f7f9fc;
  }
`;

const CellInput = styled.input`
  height: 60%;
  width: 100%;
  /* width: ${(props: { readonly inputLength: number }) =>
    props.inputLength < 7 ? "60px" : "100%"}; */

  padding-left: 6px;
  font-size: 13px;
  border: 1px solid #d2d5d8;

  &:focus {
    outline: none;
  }
`;

export function PricingTable({
  pricingRows,
  sharedState,
  locked,
  editCell,
  genesysNoPrefix,
  currencyCode,
  onCellChange,
  onDelete,
  onEditBegin,
  onEditEnd
}: PricingTableProps) {
  const dataRows: ReadonlyArray<DataRow> = pricingRows.length
    ? createDataRows(pricingRows, sharedState)
    : [];

  return (
    <Root>
      {dataRows.length > 0 && (
        <Table>
          <thead>
            <tr>
              {dataRows[0].cells.map(c => (
                <th key={c.type}>
                  {c.header}
                  {/* <S3 color="dark" weight="bold">
                    {c.header}
                  </S3> */}
                </th>
              ))}
              <th />
            </tr>
          </thead>
          {dataRows.map((r, ix) => {
            const row = pricingRows[ix];
            return (
              <tbody key={r.id}>
                <tr>
                  {r.cells.map(c =>
                    cell(
                      c,
                      row,
                      !locked &&
                        !!editCell &&
                        row.id === editCell!.rowId &&
                        c.type === editCell!.cell.type,
                      editCell && editCell.value,
                      c.canEdit,
                      genesysNoPrefix,
                      currencyCode,
                      onEditBegin,
                      onEditEnd,
                      onCellChange,
                      sharedState.translate
                    )
                  )}
                  {locked ? (
                    <td />
                  ) : (
                    <td>
                      <a onClick={() => onDelete(row.id)}>
                        <Trash height="1.6rem" />
                      </a>
                    </td>
                  )}
                </tr>
              </tbody>
            );
          })}
        </Table>
      )}
    </Root>
  );
}

function createDataRows(
  rows: ReadonlyArray<PricingRow>,
  sharedState: SharedState.State
): ReadonlyArray<DataRow> {
  return rows
    .map(r => {
      const canEdit = Authorization.hasPermission(
        sharedState.user.applicationClaims,
        "AllowArticleNoEdit",
        r.articleNo
      );

      const canSeeCost = Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysPricingClaims.canSeePricingCost
      );
      const canSeeTransferPrice = Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysPricingClaims.CanSeePricingTransferPrice
      );
      const canSeeListPrice = Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysPricingClaims.CanSeePricingListPrice
      );

      return {
        id: r.id,
        cells: [
          CellCreator.createRowNoCell(
            r.rowNo,
            true,
            true,
            sharedState.translate(LanguageTexts.rowNo()),
            false
          ),
          CellCreator.createArticleNoCell(
            r.articleNo,
            true,
            false,
            sharedState.translate(LanguageTexts.articleNo()),
            false
          ),
          CellCreator.createDescriptionCell(
            r.description,
            true,
            canEdit,
            sharedState.translate(LanguageTexts.description()),
            false
          ),
          CellCreator.createPidCell(
            r.pid,
            true,
            canEdit,
            sharedState.translate(LanguageTexts.caseshort()),
            false
          ),
          CellCreator.createQuantityCell(
            r.quantity,
            true,
            canEdit,
            sharedState.translate(LanguageTexts.quantity()),
            true
          ),
          CellCreator.createUnitCell(
            r.unit,
            true,
            false,
            sharedState.translate(LanguageTexts.unit()),
            false
          ),
          CellCreator.createCostPerUnitCell(
            r.costPerUnit,
            canSeeCost,
            canEdit,
            sharedState.translate(LanguageTexts.costPerUnit()),
            true
          ),
          CellCreator.createTransferPriceFactorCell(
            r.transferPriceFactor,
            canSeeTransferPrice && canSeeCost,
            canEdit,
            sharedState.translate(LanguageTexts.transferPriceFactor()),
            true
          ),
          CellCreator.createTransferPricePerUnitCell(
            r.transerPricePerunit,
            canSeeTransferPrice,
            canEdit,
            sharedState.translate(LanguageTexts.transferPricePerUnit()),
            true
          ),

          CellCreator.createListPriceFactorCell(
            r.listPriceFactor,
            canSeeTransferPrice && canSeeListPrice,
            canEdit,
            sharedState.translate(LanguageTexts.listPriceFactor()),
            true
          ),
          CellCreator.createListPricePerUnitCell(
            r.listPricePerUnit,
            canSeeListPrice,
            canEdit,
            sharedState.translate(LanguageTexts.listPricePerUnit()),
            true
          ),

          CellCreator.createCostSubtotalCell(
            r.quantity,
            r.costPerUnit,
            canSeeCost,
            false,
            sharedState.translate(LanguageTexts.costSubtotal()),
            true
          ),
          CellCreator.createTransferPriceSubtotalCell(
            r.quantity,
            r.transerPricePerunit,
            canSeeTransferPrice,
            false,
            sharedState.translate(LanguageTexts.transferPriceSubtotal()),
            true
          ),
          CellCreator.createListPriceSubtotalCell(
            r.quantity,
            r.listPricePerUnit,
            canSeeListPrice,
            false,
            sharedState.translate(LanguageTexts.listPriceSubtotal()),
            true
          )
        ]
      };
    })
    .map(c => ({
      ...c,
      cells: c.cells.filter(cc => cc.visibility)
    }));
}

function cell(
  cellInfo: PricingRowCell,
  row: PricingRow,
  edit: boolean,
  editText: string | undefined,
  canEdit: boolean,
  genesysNoPrefix: string,
  currencyCode: string,
  onBeginEdit: (cell: EditCell) => void,
  onEndEdit: () => void,
  onCellChange: (value: string) => void,
  translate: LanguageTexts.Translate
): JSX.Element {
  return edit ? (
    <td key={row.id + cellInfo.type}>
      <CellInput
        autoFocus={true}
        onBlur={() => onEndEdit()}
        onKeyDown={e => e.keyCode === 13 && onEndEdit()}
        onChange={e => {
          onCellChange(e.target.value);
        }}
        value={editText}
        inputLength={editText ? editText.length : 0}
      />
      {/* <div
        style={{
          display: "flex"
        }}
      >
        <CellInput
          autoFocus={true}
          onBlur={() => onEndEdit()}
          onKeyDown={e => e.keyCode === 13 && onEndEdit()}
          onChange={e => {
            onCellChange(e.target.value);
          }}
          value={editText}
          inputLength={editText ? editText.length : 0}
        />
      </div> */}
    </td>
  ) : (
    <td
      key={row.id + cellInfo.type}
      style={cellInfo.isReadOnlyStyle}
      onClick={e => {
        e.stopPropagation();
        if (canEdit) {
          onBeginEdit({
            cell: cellInfo,
            rowId: row.id,
            value: cellText(cellInfo, row, genesysNoPrefix, translate).trim()
          });
        }
      }}
    >
      <P2 weight="normal" color="secondary">
        {cellText(cellInfo, row, genesysNoPrefix, translate, currencyCode)}
      </P2>
    </td>
  );
}

function cellText(
  cellInfo: PricingRowCell,
  row: PricingRow,
  genesysNoPrefix: string,
  translate: LanguageTexts.Translate,
  currencyCode?: string
): string {
  switch (cellInfo.type) {
    case "RowNo":
      return cellInfo.rowNo.toString();
    case "ArticleNo":
      return row.type === "system"
        ? genesysNoPrefix + row.visualizerCode.split(";")[0]
        : cellInfo.articleNo;
    case "Description":
      return cellInfo.description;
    case "Pid":
      return row.type === "system" ? cellInfo.pid : "";
    case "Quantity":
      return row.type === "header" ? "" : cellInfo.quantity.toString();
    case "Unit":
      return row.type === "header"
        ? ""
        : resolveUnitLabel(cellInfo.unit, translate);
    case "CostPerUnit":
      return row.type === "header"
        ? ""
        : cellInfo.costPerUnit.toFixed(0) +
            ` ${currencyCode ? currencyCode : ""}`;
    case "TransferPriceFactor":
      return row.type === "header"
        ? ""
        : cellInfo.transferPriceFactor.toFixed(3);
    case "TransferPricePerUnit":
      return row.type === "header"
        ? ""
        : cellInfo.transferPricePerUnit.toFixed(0) +
            ` ${currencyCode ? currencyCode : ""}`;
    case "ListPriceFactor":
      return row.type === "header" ? "" : cellInfo.listpriceFactor.toFixed(3);
    case "ListPricePerUnit":
      return row.type === "header"
        ? ""
        : cellInfo.listPricePerUnit.toFixed(0) +
            ` ${currencyCode ? currencyCode : ""}`;

    case "CostSubtotal":
      return row.type === "header"
        ? ""
        : (cellInfo.quantity * Math.round(cellInfo.costPerUnit)).toFixed(0) +
            " " +
            currencyCode;
    case "TransferPriceSubtotal":
      return row.type === "header"
        ? ""
        : (
            cellInfo.quantity * Math.round(cellInfo.transferPricePerUnit)
          ).toFixed(0) + ` ${currencyCode ? currencyCode : ""}`;

    case "ListPriceSubtotal":
      return row.type === "header"
        ? ""
        : (cellInfo.quantity * Math.round(cellInfo.listPricePerUnit)).toFixed(
            0
          ) + ` ${currencyCode ? currencyCode : ""}`;
    default:
      exhaustiveCheck(cellInfo);
      throw new Error("Unknown cell type");
  }
}

function resolveUnitLabel(
  unit: string,
  translate: LanguageTexts.Translate
): string {
  const parsedUnit = Serialize.stringToUnit(unit);
  const format = parsedUnit && Format.getUnitFormat(parsedUnit, UnitsFormat);
  if (parsedUnit === undefined || format === undefined) {
    return unit;
  }

  return parsedUnit === Units.One
    ? translate(LanguageTexts.piece())
    : format.label;
}
