import * as React from "react";
import * as SharedState from "../../shared-state";
import styled from "styled-components";
import { P2, ImageDisplayer } from "@genesys/ui-elements";
import { clientConfig } from "../../config";

const StyledP2 = styled(P2)`
  margin-bottom: 2px;
  width: 300px;
`;

export function NameDiagramDisplayer({
  systemName,
  sharedState,
  effectiveSystemId
}: {
  readonly systemName: string;
  readonly sharedState: SharedState.State;
  readonly effectiveSystemId: string;
}) {
  const routeEndpoint = clientConfig.genesysBackend;

  return (
    <>
      <ImageDisplayer
        type="system-manager"
        routeEndpoint={routeEndpoint}
        accessToken={sharedState.accessToken}
        effectiveSystemId={effectiveSystemId}
      >
        <div>
          <StyledP2 weight="normal" color="secondary">
            {systemName}
          </StyledP2>
        </div>
      </ImageDisplayer>
    </>
  );
}
