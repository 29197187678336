import React from "react";
import styled from "../../../styled-components";

import { Img } from "../../../atoms/img/img";
import { ImageDisplayer } from "../../image-displayer";
import Skeleton from "@mui/material/Skeleton";
// tslint:disable-next-line
const active: string = require("ui-elements/assets/system-card/active.svg");
// tslint:disable-next-line
const inActive: string = require("ui-elements/assets/system-card/inactive.svg");

export type Status = "inProgress" | "lockedRevision" | "succesfull" | "warning";

// tslint:disable-next-line
const Container = styled.div`
  box-sizing: border-box;
  height: 224px;
  display: inline-block;
  width: 171px;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);

  :hover {
    border: 1px solid #3d3d3d;
  }
`;

// tslint:disable-next-line
const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
`;

// tslint:disable-next-line
const ActiveStatusImg = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 6px;
`;

// tslint:disable-next-line
const SubContainers = styled.div`
  width: 85%;
  margin: auto;
  margin-top: 7px;
`;

const StyledSubContainer = styled(SubContainers)`
  max-height: 33px;
  overflow: hidden;
`;

// tslint:disable-next-line
const LabelBase = styled.p`
  color: #023442;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  word-wrap: break-word;
`;
// tslint:disable-next-line
const SystemInfo = styled(LabelBase)`
  height: 32.7px;
`;

// tslint:disable-next-line
const LastUpdated = styled(LabelBase)`
  opacity: 0.6;
`;

// tslint:disable-next-line
const Title = styled.p`
  color: #023442;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
`;

// tslint:disable-next-line
const SystemImg = styled(Img)`
  height: 71px;
  /* width: 114px; */
  display: block;
  margin: auto;
  margin-top: 15px;
  object-fit: contain;
`;
const SkeletonContainer = styled.div`
  box-sizing: border-box;
  height: 224px;
  display: inline-block;
  width: 171px;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
`;

const SkeletonIconsContainer = styled.div`
  padding-top: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  > span {
    margin-right: 6px;
  }
`;

const SkeletonSystemImage = styled.div`
  height: 71px;
  width: 114px;
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 25px;
  object-fit: contain;
`;
interface Loading {
  readonly type: "loading";
}

interface Loaded {
  readonly type: "loaded";
  readonly systemType: string;
  readonly revisionNumber: string;
  readonly systemName: string;
  readonly imgUrl?: string;
  readonly lastUpdated: string | Date;
  readonly status: JSX.Element;
  readonly favourite: boolean;
  readonly lastChangedTranslation?: string;
  readonly onClick: () => void;
  readonly accessToken: string;
  readonly routeEndpoint: string;
  readonly systemId: string;
}

type Props = Loaded | Loading;

// tslint:disable-next-line
export function SystemCard(props: Props) {
  if (props.type === "loading") {
    return (
      <SkeletonContainer>
        <SkeletonIconsContainer>
          <Skeleton variant="circular" width={15} height={15} />
          <Skeleton variant="circular" width={15} height={15} />
        </SkeletonIconsContainer>
        <SkeletonSystemImage>
          <Skeleton variant="rectangular" width={114} height={71} />
        </SkeletonSystemImage>
        <SubContainers>
          <Skeleton variant="rectangular" width={80} height={10} />
        </SubContainers>
        <SubContainers>
          <Skeleton variant="rectangular" height={43} />
        </SubContainers>
      </SkeletonContainer>
    );
  }

  function onClickHandler(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    event.preventDefault();
    onClick();
  }

  const {
    systemType,
    revisionNumber,
    systemName,
    imgUrl,
    lastUpdated,
    status,
    favourite,
    lastChangedTranslation,
    onClick,
    accessToken,
    routeEndpoint,
    systemId
  } = props;
  const maxSystemNameLength = 55;
  return (
    <Container>
      <IconsContainer>
        <ActiveStatusImg
          onClick={event => onClickHandler(event)}
          src={favourite ? active : inActive}
        ></ActiveStatusImg>
        {status}
      </IconsContainer>
      <ImageDisplayer
        margin={{
          marginLeft: "-10px",
          marginTop: "27px"
        }}
        accessToken={accessToken}
        routeEndpoint={routeEndpoint}
        effectiveSystemId={systemId}
      >
        <SystemImg accessToken={accessToken} src={imgUrl}></SystemImg>
      </ImageDisplayer>

      <SubContainers>
        <Title>
          {systemType} {revisionNumber}
        </Title>
      </SubContainers>
      <StyledSubContainer>
        <SystemInfo>
          {systemName.length <= maxSystemNameLength
            ? systemName
            : systemName.substring(0, maxSystemNameLength) + "..."}
        </SystemInfo>
      </StyledSubContainer>
      <SubContainers>
        <LastUpdated>
          <>
            {lastChangedTranslation ? lastChangedTranslation : "Last Updated"}:{" "}
            {lastUpdated}
          </>
        </LastUpdated>
      </SubContainers>
    </Container>
  );
}
