import React from "react";
import styled from "../../styled-components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { defaultFontFamily } from "../../atoms/typography";

const BootstrapInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    fontSize: "13px",
    backgroundColor: "#F2F4F7",
    border: "1px solid #d2d5d8",
    padding: "0 20px",
    borderRadius: "13px",
    fontFamily: defaultFontFamily
  },
  "& .MuiSelect-select.MuiInputBase-input": {
    height: "22px",
    borderRadius: "13px"
  }
});

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
  select {
    width: 141px;
    height: 26px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

export function OperatingCasesSelect({
  onChange,
  value,
  options,
  disabled
}: {
  readonly disableBodyScrollLock?: boolean;
  readonly options: ReadonlyArray<{
    readonly value: string | number;
    readonly title: string;
  }>;
} & Pick<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  "onChange" | "value" | "disabled"
>): JSX.Element {
  return (
    <SelectContainer>
      <Select
        input={<BootstrapInput />}
        value={value}
        onChange={(e, _c) =>
          onChange !== undefined ? onChange(e as any) : undefined
        }
        disabled={disabled}
      >
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.title}
          </MenuItem>
        ))}
      </Select>
    </SelectContainer>
  );
}
