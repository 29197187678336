import React, { useState } from "react";
import styled from "../../styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  Trash,
  TrashBlue,
  TrashDisabled,
  Export,
  ExportBlue,
  ExportDisabled,
  LCCCompare,
  LCCCompareBlue,
  LCCCompareDisabled,
  LabelSelection,
  LabelSelectionBlue,
  LabelSelectionDisabled,
  SummaryBlue,
  Summary,
  SummaryDisabled
} from "../../icons";

type Tags = "delete" | "export" | "label-selection" | "lcc-compare" | "summary";

function getLabel(label: Tags) {
  switch (label) {
    case "delete":
      return "Delete";
    case "export":
      return "Export excel";
    case "label-selection":
      return "Label Selection";
    case "summary":
      return "Summary";
    case "lcc-compare":
      return "LCC Compare";
    default:
      return exhaustiveCheck(label); // Checks that all cases are covered
  }
}

function getImage(image: Tags, disabled: boolean) {
  switch (image) {
    case "delete":
      return disabled ? TrashDisabled : Trash;
    case "export":
      return disabled ? ExportDisabled : Export;
    case "label-selection":
      return disabled ? LabelSelectionDisabled : LabelSelection;
    case "summary":
      return disabled ? SummaryDisabled : Summary;
    case "lcc-compare":
      return disabled ? LCCCompareDisabled : LCCCompare;
    default:
      return exhaustiveCheck(image); // Checks that all cases are covered
  }
}

function getImageBlue(image: Tags) {
  switch (image) {
    case "delete":
      return TrashBlue;
    case "export":
      return ExportBlue;
    case "label-selection":
      return LabelSelectionBlue;
    case "summary":
      return SummaryBlue;
    case "lcc-compare":
      return LCCCompareBlue;
    default:
      return exhaustiveCheck(image); // Checks that all cases are covered
  }
}

interface Props {
  readonly disabled: boolean;
}

// tslint:disable-next-line
const ActionBase = styled.button`
  border-radius: 3px;
  color: ${(props: Props) => (props.disabled ? "#DDE4F0" : "#666e87")};
  letter-spacing: 0.5px;
  line-height: 24px;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  display: inline-flex;
  align-items: center;
  padding: 6px 9px 4px 4px;
  ${(props: Props) => (props.disabled ? " " : "cursor: pointer;")}

  label {
    font-weight: 300;
    font-size: 13px;
    text-align: center;
    padding-left: 6px;
    ${(props: Props) => (props.disabled ? " " : "cursor: pointer;")}
  }
  img {
    height: 24px;
    width: 24px;
    ${(props: Props) => (props.disabled ? " " : "cursor: pointer;")}
  }
`;

// tslint:disable-next-line
export function ActionButton(
  props: {
    readonly kind: Tags;
  } & Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "onClick" | "children" | "disabled"
  >
) {
  const { kind, disabled = false, ...rest } = props;
  const [state, setState] = useState({
    img: getImage(kind, disabled),
    backgroundColor: "linear-gradient(180deg, #f8f8f8 0%, #fafafa 100%)"
  });

  const Img2 = state.img;
  return (
    <ActionBase
      style={{
        background: state.backgroundColor
      }}
      onMouseEnter={event => {
        event.stopPropagation();
        event.preventDefault();
        if (!disabled) {
          setState({
            img: getImageBlue(kind),
            backgroundColor: "white"
          });
        }
      }}
      onMouseLeave={event => {
        event.stopPropagation();
        event.preventDefault();
        if (!disabled) {
          setState({
            img: getImage(kind, false),
            backgroundColor: "linear-gradient(180deg, #f8f8f8 0%, #fafafa 100%)"
          });
        }
      }}
      disabled={disabled}
      {...rest}
    >
      <Img2 />
      <label>{getLabel(kind)}</label>
    </ActionBase>
  );
}
