import React, { useState } from "react";
import styled from "../../../styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { zIndex } from "../../../zIndex";
import { PrimaryColors } from "../../../colors";
// tslint:disable-next-line
const infoSvg = require("ui-elements/assets/alert-accordion/info.svg");
// tslint:disable-next-line
const arrowDown = require("ui-elements/assets/alert-accordion/arrow-down.svg");
// tslint:disable-next-line
const arrowUp = require("ui-elements/assets/alert-accordion/arrow-up.svg");

interface Props {
  readonly width?: string;
  readonly color?: string;
}

const AlertHeader = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  cursor: pointer;
`;

const AlertChild = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  margin-bottom: 10px;
`;

const StyledDiv = styled.div`
  height: 40px;
`;

const Div = styled.div`
  width: 93%;
  max-height: 254px;
  padding-top: 7px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #023442;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const AlertBody = styled.div`
  width: ${(props: Props) => props.width};
  display: flex;
  position: absolute;
  top: -15px;
  background-color: ${(props: Props) => props.color};
  z-index: ${zIndex.Dropdown};
  border-radius: 3px;
  padding-bottom: 10px;
`;

const AlertContainer = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props: Props) => props.width};
  border-radius: 3px;
  background-color: ${(props: Props) => props.color}; ;
`;

const ImgInfo = styled.img`
  height: 24px;
  width: 24px;
`;

const ImgForDropDown = styled.img`
  height: 5px;
  width: 11px;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 7%;
  cursor: pointer;
`;

const NotificationContainer = styled.div`
  height: 20px;
  width: 38.86px;
  border-radius: 10px;
  background-color: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Counter = styled.p`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;

  color: #ffffff;
`;

const InnerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
`;

const BaseP = styled.p`
  color: #ffffff;
  font-size: 14px;
  margin-left: 10px;
  word-wrap: break-word;
  max-width: 90%;
`;

const PWithLineClamp = styled(BaseP)<{ readonly lineClamp: number | "none" }>`
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.lineClamp};
  -webkit-box-orient: vertical;
`;
const LabelForInfo = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  word-wrap: break-word;
  max-width: 90%;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  overflow-y: auto;
  max-height: 32px;
  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 3px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #023442;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

type WarningType = "info" | "warning" | "error";

export function AlertAccordion(props: {
  readonly width?: string;
  readonly onClick?: () => void;
  readonly title: string;
  readonly alerts: ReadonlyArray<{
    readonly title: string;
    readonly body: string;
  }>;
  readonly warningType: WarningType;
}) {
  const { width = "680px", onClick, title, alerts = [] } = props;
  const [state, setState] = useState({
    isOpen: false
  });

  const hasMoreThanOneMessage = alerts.length > 1;

  function onClickHandler(event: MouseEvent | TouchEvent) {
    event.stopPropagation();
    event.preventDefault();
    setState({
      isOpen: !state.isOpen
    });
  }

  function childOnClickHandler(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    if (onClick) {
      event.preventDefault();

      onClick();
    }
  }

  const headerOnly = (
    <AlertHeader onClick={event => onClickHandler(event as any)}>
      <InnerDiv>
        <ImgInfo src={infoSvg} />
        <LabelContainer>
          <LabelForInfo>
            {hasMoreThanOneMessage ? (
              title
            ) : (
              <>
                {alerts[0].title && <Bold>{alerts[0].title} </Bold>}
                {alerts[0].body}
              </>
            )}
          </LabelForInfo>
        </LabelContainer>

        <NotificationContainer>
          {alerts.length > 0 ? (
            <Counter> {alerts.length < 10 ? alerts.length : "9+"}</Counter>
          ) : (
            <div></div>
          )}
        </NotificationContainer>
        {hasMoreThanOneMessage && (
          <ImgForDropDown src={arrowDown}></ImgForDropDown>
        )}
      </InnerDiv>
    </AlertHeader>
  );

  const fullBody = (
    <ClickAwayListener onClickAway={event => onClickHandler(event)}>
      <AlertBody
        onClick={event => onClickHandler(event as any)}
        color={getColor(props.warningType)}
        width={width}
      >
        <Div>
          {alerts.map((alert, i) => (
            <AlertRow key={`${alert}${i}`} onClickHandler={childOnClickHandler}>
              <>
                {alert.title && <Bold>{alert.title} </Bold>}
                {alert.body}
              </>
            </AlertRow>
          ))}
        </Div>
        <ImgContainer>
          <ImgForDropDown src={arrowUp} />
        </ImgContainer>
      </AlertBody>
    </ClickAwayListener>
  );
  return (
    <StyledDiv>
      <AlertContainer
        color={getColor(props.warningType)}
        width={width}
        onClick={event => {
          onClickHandler(event as any);
        }}
      >
        {state.isOpen ? fullBody : headerOnly}
      </AlertContainer>
    </StyledDiv>
  );
}

function getColor(warningType: WarningType) {
  if (warningType === "info") {
    return "#2487F5";
  } else if (warningType === "warning") {
    return PrimaryColors.gold;
  }
  return PrimaryColors.red;
}

function AlertRow({
  children,
  onClickHandler
}: {
  readonly children: React.ReactNode;
  readonly onClickHandler: (event: React.MouseEvent<HTMLElement>) => void;
}) {
  const [isOverflown, setIsOverflown] = React.useState(false);
  const [lineClamp, setLineClamp] = React.useState<3 | "none">(3);

  const ref = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!ref.current) {
      setIsOverflown(false);
      return;
    }
    if (ref.current.scrollHeight > ref.current.clientHeight) {
      setIsOverflown(true);
    }
  }, [ref]);

  return (
    <AlertChild onClick={onClickHandler}>
      <ImgInfo src={infoSvg} />

      <div style={{ width: "93%" }}>
        <PWithLineClamp ref={ref as any} lineClamp={lineClamp}>
          {children}
        </PWithLineClamp>
        <ShowText
          isOverflown={isOverflown}
          lineClamp={lineClamp}
          setLineClamp={setLineClamp}
        />
      </div>
    </AlertChild>
  );
}

const ShowButton = styled(BaseP)`
  /* color: #00b1f7; */
  cursor: pointer;
  font-style: italic;
`;

const CenterFlex = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-top: 4px;
    margin-left: 3px;
  }
`;

function ShowText({
  isOverflown,
  lineClamp,
  setLineClamp
}: {
  readonly isOverflown: boolean;
  readonly lineClamp: number | "none";
  readonly setLineClamp: (value: React.SetStateAction<3 | "none">) => void;
}) {
  if (!isOverflown) {
    return null;
  }

  if (lineClamp === "none") {
    return (
      <CenterFlex>
        <ShowButton
          onClick={() => {
            setLineClamp(3);
          }}
        >
          show less
        </ShowButton>
        <ImgForDropDown src={arrowUp} />
      </CenterFlex>
    );
  } else {
    return (
      <CenterFlex>
        <ShowButton
          onClick={() => {
            setLineClamp("none");
          }}
        >
          show more
        </ShowButton>
        <ImgForDropDown src={arrowDown} />
      </CenterFlex>
    );
  }
}
