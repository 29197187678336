import * as React from "react";
import {
  TopMenuContainer,
  MenuCardExpanded,
  TopMenu as TopMenuElement,
  TopMenuItem,
  TopMenuItemEndIconContainer,
  TopMenuItemStartIconContainer,
  MenuCardCollapsed,
  TopMenuItemSelected,
  StyleMenuButton,
  MenuCardContent
} from "./elements";
import * as Types from "./types";
import { AddComponent, StatusError } from "@genesys/ui-elements/";
import * as AddComponentMenu from "../add-component-menu/";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as SharedState from "../../shared-state";
import { ScreenMenu } from "./screen-menu";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Product from "../product";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import * as System from "../system";

export interface TopMenuProps {
  readonly system: System.System;
  readonly dispatch: Dispatch<State.Action>;
  readonly state: Types.State;
  readonly sharedState: SharedState.State;
  readonly addComponentMenuGroups: ReadonlyArray<AddComponentMenu.AddComponentGroup>;
  readonly systemInputsScreens: ReadonlyArray<Types.Screen>;
  readonly engineeringOutputsScreens: ReadonlyArray<Types.Screen>;
  readonly translate: LanguageTexts.Translate;
  readonly addComponent: (
    componentId: string,
    productId: string,
    productSectionIds: ReadonlyArray<string>
  ) => void;
  readonly productData: Product.ProductData;
  readonly systemStatus: number;
}

export function TopMenu(props: TopMenuProps): JSX.Element {
  const selectItem = (item: Types.Item | undefined) =>
    props.dispatch(State.Action.selectedItem(item));

  const EndIcon = (props: { readonly hasError?: boolean }) => (
    <TopMenuItemEndIconContainer>
      {props.hasError ? <StatusError /> : null}
    </TopMenuItemEndIconContainer>
  );

  const item = (
    title: string,
    onClick: () => void,
    selected: boolean,
    disabled: boolean,
    hasError: boolean,
    startIcon?: React.ReactNode
  ) => {
    const ItemContent = () => (
      <>
        {startIcon && (
          <TopMenuItemStartIconContainer>
            {startIcon}
          </TopMenuItemStartIconContainer>
        )}
        <StyleMenuButton hasMenu={true} isActive={selected} isOpen={selected}>
          {title}
        </StyleMenuButton>
        <EndIcon hasError={hasError} />
      </>
    );

    return selected ? (
      <TopMenuItemSelected
        disabled={disabled}
        onClick={() => selectItem(undefined)}
      >
        <ItemContent />
      </TopMenuItemSelected>
    ) : (
      <TopMenuItem
        disabled={disabled}
        onClick={() => (!disabled ? onClick() : selectItem(undefined))}
      >
        <ItemContent />
      </TopMenuItem>
    );
  };

  const topMenu = (topMenuItem: Types.Item | undefined) => (
    <TopMenuElement>
      {item(
        props.translate(LanguageTexts.addComponent()),
        () => selectItem("add-component"),
        topMenuItem === "add-component",
        props.systemStatus >= SystemStatus.LockSuccess,
        false,
        <AddComponent height="1.6rem" />
      )}
      {item(
        props.translate(LanguageTexts.systemInputs()),
        () => selectItem("system-inputs"),
        topMenuItem === "system-inputs",
        false,
        props.systemInputsScreens.some(s => s.hasError)
      )}
      {item(
        props.translate(LanguageTexts.engineeringOutputs()),
        () => selectItem("engineering-outputs"),
        topMenuItem === "engineering-outputs",
        false,
        props.engineeringOutputsScreens.some(s => s.hasError)
      )}
    </TopMenuElement>
  );

  return (
    <TopMenuContainer>
      {props.state.selectedItem !== undefined ? (
        <ClickAwayListener onClickAway={() => selectItem(undefined)}>
          <MenuCardExpanded>
            {topMenu(props.state.selectedItem)}
            <MenuCardContent>
              <MenuContent {...props} />
            </MenuCardContent>
          </MenuCardExpanded>
        </ClickAwayListener>
      ) : (
        <MenuCardCollapsed>{topMenu(undefined)}</MenuCardCollapsed>
      )}
    </TopMenuContainer>
  );
}

function MenuContent(props: TopMenuProps): JSX.Element {
  console.log("tesst");
  switch (props.state.selectedItem) {
    case "add-component":
      return (
        <AddComponentMenu.AddComponentMenu
          dispatch={Dispatch.map(
            State.Action.dispatchAddComponentMenu,
            props.dispatch
          )}
          addComponent={props.addComponent}
          groups={props.addComponentMenuGroups}
          state={props.state.addComponentMenuState}
          sharedState={props.sharedState}
          productData={props.productData}
          system={props.system}
        />
      );
    case "system-inputs":
      return <ScreenMenu screens={props.systemInputsScreens} />;
    case "engineering-outputs":
      return <ScreenMenu screens={props.engineeringOutputsScreens} />;
    default:
      throw new Error("Unknown menu item: " + props.state.selectedItem);
  }
}
