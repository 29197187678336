import styled from "styled-components";
import { Img, zIndex, PrimaryColors } from "@genesys/ui-elements";

export const SystemActionsIconContainer = styled.div`
  position: relative;
  top: 2px;
  right: 7px;
`;
export const SystemActionsContainer = styled.div`
  z-index: ${zIndex.Dropdown};
  position: absolute;
  border: 1px solid #dddddd;
`;

export const Container = styled.div`
  box-sizing: border-box;
  height: 121px;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;

  :hover {
    border: 1px solid #3d3d3d;
  }
`;

export const ErrorContainer = styled(Container)`
  background-color: ${PrimaryColors.lightRed};
  padding: 24px;
  cursor: pointer;
`;

export const MiddleContainer = styled.div`
  width: 50%;
`;
export const SystemImgContainer = styled.div``;

export const SystemImage = styled(Img)`
  height: 103px;
  width: 145px;
  display: block;
  margin: auto;
  object-fit: contain;
`;

export const LabelBase = styled.p`
  color: #16181e;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  word-wrap: break-word;
`;
export const SystemInfo = styled(LabelBase)`
  padding-right: 12px;
`;

export const LastUpdated = styled(LabelBase)`
  opacity: 0.6;
  color: #373737;
  font-style: italic;
  margin-top: 8px;
`;

export const ActiveStatusImg = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 14px;
  cursor: pointer;
`;

export const StatusImg = styled.div`
  margin-right: 14px;
  margin-top: 3px;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SkeletonContainer = styled.div`
  height: 121px;
  width: 701px;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
