import React, { useState } from "react";
import styled from "../../styled-components";
// import { PrimaryColors } from "../../colors";
// import { ArrowDownWhite } from "../../icons";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { zIndex } from "../../zIndex";
import { InlineButtonComp } from "../button/inline-button";
// import { StyledButton } from "../button/standard-button";
import * as StandardButton from "../button";

import { ArrowDownNew, ArrowUpNew } from "../../icons";
const ListContainer = styled.div`
  position: relative;
`;

const List = styled.ul`
  list-style-type: none;
  position: absolute;
  background-color: #ffffff;
  margin: 0;
  margin-left: 0x;
  border: 1px solid #dddddd;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: content-box;
  width: 100%;
  z-index: ${zIndex.Dropdown};

  :before {
    position: absolute;
    transform: rotate(45deg);
    display: block;
    right: 17px;
    top: -5px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-top: 1px solid #dddddd;
    border-bottom: 0px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 0px solid #dddddd;
    content: "";
  }

  li {
    padding: 8px 10px 8px 16px;
    cursor: pointer;
    color: #373737;
    font-size: 13px;
    h4 {
      margin: 0;
    }

    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

const Button = styled(InlineButtonComp)`
  padding-left: 15px;
  padding-right: 16px;

  label {
    display: flex;
    align-items: center;
  }
  /* border: 0; */
  :focus {
    outline: none;
  }
  img:first-child {
    margin-right: 15px;
  }
  img:last-child {
    margin-left: 15px;
    margin-right: 0px;

    &:hover {
      transform: scale(1.2);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add a soft shadow */
    }
  }
`;

const Container = styled.div`
  display: inline-block;
`;

const ButtonContainer = styled.div`
  /* display: flex;
  align-items: stretch; */
  button {
    padding-top: ${(props: Props) => (props.size === "Large" ? "13px" : "8px")};
    padding-bottom: ${(props: Props) =>
      props.size === "Large" ? "13px" : "8px"};
  }
`;

// const ArrowContainer = styled.div`
//   display: inline-flex;
//   width: ${(props: Props) => (props.size === "Large" ? "40px" : "35px")};
//   background-color: ${PrimaryColors.green};
//   align-items: center;

//   justify-content: center;
//   border-left: 1px solid rgba(0, 0, 0, 0.2);
//   border-radius: 0 3px 3px 0;

//   ${(props: Props) =>
//     StandardButton.getBorderStyles({ ...props, size: props.size })}
//   ${(props: Props) =>
//     StandardButton.getColorStyles({ ...props, size: props.size })}
//   img {
//     height: 6px;
//     width: 12px;
//   }

//   ${(props: Props) => props.disabled && "opacity:0.5;"};
//   ${(props: Props) => !props.disabled && "cursor: pointer;"};

//   /* &:disabled {
//     opacity: 0.5;
//   } */
// `;

interface Props {
  readonly buttonType: StandardButton.ButtonType;
  readonly size: StandardButton.Size;
  readonly disabled?: boolean;
}

// tslint:disable-next-line
export function DropDownButton(
  props: {
    readonly buttonType?: StandardButton.ButtonType;
    readonly size?: StandardButton.Size;
    readonly options: ReadonlyArray<{
      readonly value: string;
      readonly onClickHandler: () => void;
    }>;
  } & Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    "onClick" | "children" | "style" | "disabled"
  >
) {
  const {
    buttonType = "PrimaryGreen",
    size = "Large",
    disabled,
    options,
    ...rest
  } = props;

  const [state, setState] = useState({
    isOpen: false,
    value: options[0].value,
    currentOnClick: options[0].onClickHandler,
    imgSrcForInline: ArrowDownNew
  });

  const SomeImage = state.imgSrcForInline;

  return (
    <ClickAwayListener
      onClickAway={() =>
        setState({
          isOpen: false,
          value: state.value,
          currentOnClick: state.currentOnClick,
          imgSrcForInline: ArrowDownNew
        })
      }
    >
      <Container>
        <ButtonContainer size={size} buttonType={buttonType}>
          <Button
            buttonType="PrimaryBlue"
            size="Large"
            disabled={disabled}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              state.currentOnClick();
            }}
            {...rest}
          >
            <p> {state.value} </p>
            <SomeImage
              onClick={event => {
                if (disabled) {
                  return;
                }
                event.preventDefault();
                event.stopPropagation();
                setState({
                  isOpen: !state.isOpen,
                  value: state.value,
                  currentOnClick: state.currentOnClick,
                  imgSrcForInline: state.isOpen ? ArrowDownNew : ArrowUpNew
                });
              }}
            />
          </Button>
          {/* <Button
            size={size}
            buttonType={buttonType}
            disabled={disabled}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              state.currentOnClick();
            }}
            {...rest}
          >
            {state.value}
          </Button>

          <ArrowContainer
            disabled={disabled}
            size={size}
            buttonType={buttonType}
            onClick={event => {
              if (disabled) {
                return;
              }
              event.preventDefault();
              event.stopPropagation();
              setState({
                isOpen: !state.isOpen,
                value: state.value,
                currentOnClick: state.currentOnClick
              });
            }}
          >
            <ArrowDownWhite />
          </ArrowContainer> */}
        </ButtonContainer>
        <ListContainer>
          {state.isOpen && (
            <List>
              {options.map((option, i) => {
                return option.value !== state.value ? (
                  <li
                    key={`${option.value} + ${i}`}
                    value={option.value}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      option.onClickHandler();
                      setState({
                        isOpen: false,
                        value: option.value,
                        currentOnClick: option.onClickHandler,
                        imgSrcForInline: ArrowDownNew
                      });
                    }}
                  >
                    {option.value}
                  </li>
                ) : null;
              })}
            </List>
          )}
        </ListContainer>
      </Container>
    </ClickAwayListener>
  );
}
