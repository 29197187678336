import React from "react";
import styled from "../../../styled-components";

import { CheckBox } from "../../../atoms/checkbox";

const Container = styled.div`
  display: inline-block;
`;
const HeaderContainer = styled.div`
  background-color: #00adf2;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
`;

const Body = styled.div``;

const StyledLabel = styled.label`
  margin-left: -7px;
  font-size: 13px;
`;

export function HourPickerCard({
  hours,
  title
}: {
  readonly title: string;
  readonly hours: ReadonlyArray<{
    readonly time: string;
    readonly isChecked: boolean;
    readonly disabled?: boolean;
    readonly onClick: () => void;
  }>;
}) {
  return (
    <Container>
      <HeaderContainer>{title}</HeaderContainer>
      <Body>
        {hours.map(h => (
          <div key={h.time}>
            <CheckBox
              onClick={h.onClick}
              disabled={h.disabled}
              isChecked={h.isChecked}
              children={<StyledLabel>{h.time}</StyledLabel>}
            />
          </div>
        ))}
      </Body>
    </Container>
  );
}
