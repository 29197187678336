import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { PrimaryColors } from "../../colors";
import { defaultFontFamily } from "../../atoms/typography";

const useStyles = makeStyles({
  popper: {
    fontFamily: defaultFontFamily,
    fontSize: "11px",
    backgroundColor: PrimaryColors.grey
  },
  arrow: {
    color: PrimaryColors.grey
  }
});

export function ToolTipWrapper({
  children,
  title,
  placement = "bottom",
  padding,
  onClick
}: {
  readonly children: React.ReactNode;
  readonly title: React.ReactChild;
  readonly placement?: "bottom" | "top";
  readonly padding?: string;
  readonly onClick?: () => void;
}) {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{
        tooltip: classes.popper,
        arrow: classes.arrow
      }}
      style={{ padding: padding || "4px", margin: 0, display: "inline-block" }}
      title={title}
      placement={placement}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      arrow
    >
      <div>{children}</div>
    </Tooltip>
  );
}
