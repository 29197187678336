import styled from "styled-components";
import { SquarePattern } from "@genesys/ui-elements";

export const Container = styled.div`
  display: inline-block;
  width: 50%;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const FavouritesLabel = styled.label`
  color: #16181e;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
`;

export const Label = styled.label`
  display: block;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
`;

export const Body = styled.div`
  a {
    text-decoration: none;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
`;

export const SystemItem = styled.a`
  margin-bottom: 24px;
  display: block;
`;

export const BottomContainer = styled(SquarePattern)`
  /* background: #023442; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 181px;
  border-radius: 9px;
  background-size: 10px 10px;
`;
