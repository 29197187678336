import { wrap } from "../icon-wrapper";
import { PrimaryColors } from "../../colors";

import {
  createSystemFeaturesSvg,
  createSolidContactsSvg,
  createSparePartsSvg,
  createSystemAccessoriesSvg,
  createpsychrometricChartSvg,
  createEnergySvg,
  createPlenumSizeSvg,
  createPropertyGroupsSvg,
  createBuildInRevitSvg,
  createElectricalOptionSvg,
  createMechanicalOptionsSvg,
  createMechanicalSvg,
  createOperatingCaseSvg,
  createPidSvg,
  createPriceSvg,
  createPrinterSvg,
  createNotesIconSvg,
  createFileSvg
} from "../src/embedded-svg-creators";

const defaultColor = PrimaryColors.blue400;
const hoverColor = PrimaryColors.blue500;

const systemConfiguratorIconsNormal = {
  color: defaultColor,
  backgroundColor: "#FAFAFA",
  borderColor: "#F0F0F0"
};

const systemConfiguratorIconsHover = {
  color: hoverColor,
  backgroundColor: "white",
  borderColor: "#3D3D3D"
};

// tslint:disable
export const Files = wrap(
  createFileSvg(defaultColor),
  createFileSvg(hoverColor)
);
export const Price = wrap(
  createPriceSvg(defaultColor),
  createPriceSvg(hoverColor)
);
export const Printer = wrap(
  createPrinterSvg(defaultColor),
  createPrinterSvg(hoverColor)
);
export const MenuDots = wrap(
  require("ui-elements/assets/system-configurator/menu-dots.svg")
);
export const StatusComplete = wrap(
  require("ui-elements/assets/system-configurator/status-complete.svg")
);
export const StatusError = wrap(
  require("ui-elements/assets/system-configurator/status-error.svg")
);
export const StatusInProgress100 = wrap(
  require("ui-elements/assets/system-configurator/status-in-progress-100.svg")
);
export const StatusInProgressYellow = wrap(
  require("ui-elements/assets/system-configurator/status-in-progress-yellow.svg")
);
export const StatusLocked = wrap(
  require("ui-elements/assets/system-configurator/status-locked.svg")
);
export const AddComponent = wrap(
  require("ui-elements/assets/system-configurator/add-component.svg")
);
export const BuildInRevit = wrap(
  createBuildInRevitSvg(systemConfiguratorIconsNormal),
  createBuildInRevitSvg(systemConfiguratorIconsHover)
);

// export const SolidContact = wrap(
//   require("ui-elements/assets/system-configurator/solid-contact.svg"),
//   require("ui-elements/assets/system-configurator/hover/solid-contact-hover.svg")
// );

export const SolidContact = wrap(
  createSolidContactsSvg(systemConfiguratorIconsNormal),
  createSolidContactsSvg(systemConfiguratorIconsHover)
);

export const Cooler = wrap(
  require("ui-elements/assets/system-configurator/cooler.svg")
);

export const Condenser = wrap(
  require("ui-elements/assets/system-configurator/air-cooled-condenser.svg")
);
export const ElectricalOptions = wrap(
  createElectricalOptionSvg(systemConfiguratorIconsNormal),
  createElectricalOptionSvg(systemConfiguratorIconsHover)
);
export const Energy = wrap(
  createEnergySvg(systemConfiguratorIconsNormal),
  createEnergySvg(systemConfiguratorIconsHover)
);
export const Fan = wrap(
  require("ui-elements/assets/system-configurator/fan.svg")
);
export const Filter = wrap(
  require("ui-elements/assets/system-configurator/filter.svg")
);

export const FilterIcon = wrap(require("ui-elements/assets/filter/filter.svg"));

export const Heater = wrap(
  require("ui-elements/assets/system-configurator/heater.svg")
);
export const MechanicalOptions = wrap(
  createMechanicalOptionsSvg(systemConfiguratorIconsNormal),
  createMechanicalOptionsSvg(systemConfiguratorIconsHover)
);
export const MechanicalSketch = wrap(
  createMechanicalSvg(systemConfiguratorIconsNormal),
  createMechanicalSvg(systemConfiguratorIconsHover)
);
export const Measurements = wrap(
  require("ui-elements/assets/system-configurator/measurements.svg")
);
export const OperatingCases = wrap(
  createOperatingCaseSvg(systemConfiguratorIconsNormal),
  createOperatingCaseSvg(systemConfiguratorIconsHover)
);
export const Pid = wrap(
  createPidSvg(systemConfiguratorIconsNormal),
  createPidSvg(systemConfiguratorIconsHover)
);
export const PlenumSize = wrap(
  createPlenumSizeSvg(systemConfiguratorIconsNormal),
  createPlenumSizeSvg(systemConfiguratorIconsHover)
);
export const PropertyGroups = wrap(
  createPropertyGroupsSvg(systemConfiguratorIconsNormal),
  createPropertyGroupsSvg(systemConfiguratorIconsHover)
);
export const PsychrometricChart = wrap(
  createpsychrometricChartSvg(systemConfiguratorIconsNormal),
  createpsychrometricChartSvg(systemConfiguratorIconsHover)
);
export const SectionDefault = wrap(
  require("ui-elements/assets/system-configurator/section.svg")
);

export const Section = wrap(
  require("ui-elements/assets/system-configurator/new-section.svg")
);
export const SpareParts = wrap(
  createSparePartsSvg(systemConfiguratorIconsNormal),
  createSparePartsSvg(systemConfiguratorIconsHover)
);

export const BlackBox = wrap(
  require("ui-elements/assets/system-configurator/blackbox.svg")
);

export const ServiceOptions = wrap(
  require("ui-elements/assets/system-configurator/service-option.svg")
);
// export const SystemAccessories = wrap(
//   require("ui-elements/assets/system-configurator/system-accessories.svg"),
//   require("ui-elements/assets/system-configurator/hover/system-accessories-hover.svg")
// );

export const SystemAccessories = wrap(
  createSystemAccessoriesSvg(systemConfiguratorIconsNormal),
  createSystemAccessoriesSvg(systemConfiguratorIconsHover)
);

// export const SystemFeatures = wrap(
//   require("ui-elements/assets/system-configurator/system-features.svg"),
//   require("ui-elements/assets/system-configurator/hover/system-features-hover.svg")
// );

export const SystemFeatures = wrap(
  createSystemFeaturesSvg(systemConfiguratorIconsNormal),
  createSystemFeaturesSvg(systemConfiguratorIconsHover)
);

export const EditBlue = wrap(
  require("ui-elements/assets/system-configurator/edit-blue.svg")
);

export const EditGrey = wrap(
  require("ui-elements/assets/system-configurator/edit-grey.svg")
);

export const EnhanceBlue = wrap(
  require("ui-elements/assets/system-configurator/enhance-blue.svg")
);
export const MoveBlue = wrap(
  require("ui-elements/assets/system-configurator/move-blue.svg")
);
export const Notes = wrap(
  createNotesIconSvg(defaultColor),
  createNotesIconSvg(hoverColor)
);
export const AccessoriesBlue = wrap(
  require("ui-elements/assets/system-configurator/accessories-blue.svg")
);

export const ExpandIcon = wrap(
  require("ui-elements/assets/system-configurator/expand.svg")
);

export const CollapseIcon = wrap(
  require("ui-elements/assets/system-configurator/collapse.svg")
);
