import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Types from "./types";
import * as System from "../../../system";
import * as Tools from "./tools";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { PropertyValueSet } from "@genesys/property";
import {
  ListResultView,
  ChartResultView,
  ClimateCoolingDataType
} from "../../../../energy-result-visualizer";

const Container = styled.div`
  margin-top: 10px;
  padding: 15px;
`;

const StyledTitle = styled.label`
  color: #00b1f7;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 7px;
  display: block;
`;

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > span {
    margin-right: 5px;
  }
`;

const binVisualizers: ReadonlyArray<Types.BinVisualizer> = ["List", "Chart"];

export function DetailedView({
  sharedState,
  state,
  system,
  dispatch
}: {
  readonly system: System.System;
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (!state) {
    return null;
  }
  const translate = sharedState.translate;
  const selectedBinVisualizer = state.resultVisualizer;
  const selectedListPreset = state.listPreset;

  const energyResult = Tools.getEnergyResult(
    [system],
    state!.productQueryData!.product.systemType.energyTotals,
    state!.productQueryData!.product.systemType.energyList,
    "SYS"
  );

  const mCompareResult = Tools.getEnergyResult(
    [system],
    state!.productQueryData!.product.systemType.energyTotals,
    state!.productQueryData!.product.systemType.energyList,
    "MCS"
  );

  const binSelections = PropertyValueSet.fromString(system.binSelections || "");

  const dataType = PropertyValueSet.getText(
    KnownProperties.climateCoolingDataType,
    binSelections
  )! as ClimateCoolingDataType;

  const selectedDataType = state.selectedClimateCoolingDataType || dataType;
  const energyResults = [
    {
      ...energyResult,
      name: LanguageTexts.systemTypeIdText(system.file.systemTypeId)
    },
    {
      ...mCompareResult,
      name: LanguageTexts.mCompareSystem()
    }
  ];

  return (
    <Container>
      <StyledTitle>{translate(LanguageTexts.detailedResults())}</StyledTitle>
      <SubContainer>
        <span>{translate(LanguageTexts.viewAs())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={binVisualizers.map(b => ({
            value: b,
            title: b
          }))}
          value={selectedBinVisualizer}
          onChange={e => {
            dispatch(
              Action.setBinVisualizer(e.target.value as Types.BinVisualizer)
            );
          }}
        />
      </SubContainer>
      {selectedBinVisualizer === "List" ? (
        <ListResultView
          onFormatCleared={(fieldGroup, fieldName) => {
            dispatch(Action.onFormatCleared(fieldGroup, fieldName));
          }}
          onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            );
          }}
          energyResults={energyResults}
          selectedListPreset={selectedListPreset}
          sharedState={sharedState}
          listPresetOnChange={preset => {
            dispatch(Action.setListPreset(preset));
          }}
        />
      ) : (
        <ChartResultView
          climateCoolingDataType={dataType}
          binSize={
            PropertyValueSet.getInteger(KnownProperties.binSize, binSelections)!
          }
          energyResults={energyResults}
          selectedClimateCoolingDataType={selectedDataType}
          climateCoolingDataTypeOnChange={id =>
            dispatch(Action.setClimateCoolingType(id))
          }
          selectedChartPresetId={state.selectedChartPreset}
          sharedState={sharedState}
          chartPresets={state.energyChartPresets}
          chartPresetOnChange={id => dispatch(Action.setChartPreset(id))}
        />
      )}
    </Container>
  );
}
