import * as React from "react";
import styled from "../styled-components";
import { Props } from "./types";

const StyledImg = styled.img<{
  readonly width?: number | string;
  readonly height?: number | string;
  readonly useOpacity: boolean;
  readonly pointer: boolean;
}>`
  ${props => props.width && `width: ${props.width}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => (props.useOpacity ? "opacity: 0.5" : "opacity:1")};
  ${props => props.pointer && `cursor: pointer`};
`;

export function wrap(
  src:
    | string
    | {
        readonly default: string;
      },
  hoverSrc?:
    | string
    | {
        readonly default: string;
      },
  activeSrc?: string
) {
  return (props: Props): JSX.Element => {
    const [isHoverInternal, setHover] = React.useState(false);
    const isHover = isHoverInternal || props.state === "hover";

    let currentSrc: string | { readonly default: string };

    if (isHover && hoverSrc) {
      if (typeof hoverSrc === "string") {
        currentSrc = hoverSrc;
      } else {
        currentSrc = hoverSrc.default;
      }
    } else if (props.state === "active" && activeSrc) {
      currentSrc = activeSrc;
    } else {
      if (typeof src === "string") {
        currentSrc = src;
      } else {
        currentSrc = src.default;
      }
    }

    const { onClick, onMouseDown, onMouseUp, disabled, ...rest } = props;

    return (
      <StyledImg
        useOpacity={!!disabled}
        pointer={!!(!disabled && onClick)}
        onMouseLeave={event => {
          if (hoverSrc) {
            event.stopPropagation();
            event.preventDefault();
            setHover(false);
          }
        }}
        onMouseUp={event => {
          if (disabled || !onMouseUp) {
            return;
          }
          event.stopPropagation();
          event.preventDefault();
          onMouseUp(event);
        }}
        onMouseDown={event => {
          if (disabled || !onMouseDown) {
            return;
          }
          event.stopPropagation();
          event.preventDefault();
          onMouseDown(event);
        }}
        onMouseEnter={event => {
          if (hoverSrc) {
            event.stopPropagation();
            event.preventDefault();
            setHover(true);
          }
        }}
        src={currentSrc}
        onClick={event => {
          if (onClick && !disabled) {
            event.preventDefault();

            onClick(event);
          }
        }}
        {...rest}
      />
    );
  };
}
