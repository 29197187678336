import * as React from "react";
import * as Types from "../types";
import * as SharedState from "../../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as System from "../../../../system";
import { State, Action } from "../state";
import { Quantity, Unit } from "@genesys/uom";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet, PropertyFilter } from "@genesys/property";
import styled from "styled-components";
import {
  AmountPropertySelector,
  SimplifiedProps
} from "../../../../../amount-property-selector";
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 30px;

  color: #8f9bb3;
  font-size: 13px;
  font-weight: 500;
`;

const fieldGroup = "EnergyPriceInputFieldGroup";

export function EnergyPriceSelectorView({
  energyItems,
  sharedState,
  system,
  energyPrice,
  readonly,
  dispatch
}: {
  readonly energyItems: ReadonlyArray<Types.EnergyItem>;
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly system: System.System;
  readonly readonly: boolean;
  readonly energyPrice: PropertyValueSet.PropertyValueSet;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const currency = sharedState.user.currencies.find(
    c => c.id === system.currencyCode
  )!;

  const currencySymbol = currency.signBefore || currency.signAfter;

  return (
    <Container>
      {energyItems.map((item, i) => {
        return [
          <div key={item.name}>
            {sharedState.translate(LanguageTexts.dynamicText(item.name))}
          </div>,
          <AmountPropertySelector
            key={i}
            {...getAmountPropertySelectorOptions(
              dispatch,
              sharedState,
              item,
              energyPrice,
              readonly
            )}
            extraLabel={item.cost ? currencySymbol : ""}
          />
        ];
      })}
    </Container>
  );
}

function getAmountPropertySelectorOptions(
  dispatch: Dispatch<Action>,
  sharedState: SharedState.State,
  item: Types.EnergyItem,
  pvs: PropertyValueSet.PropertyValueSet,
  readonly: boolean
): SimplifiedProps {
  return {
    // Defines a single property
    type: "with-simplified-props",
    fieldName: item.name,
    propertyName: item.name,
    quantity: item.defaultAmount.unit.quantity,
    translate: sharedState.translate,

    propertyValueSet: pvs,
    fieldGroup: fieldGroup,
    getAmountFormat: sharedState.screenAmounts.getAmountFormat,
    onValueChange: () => undefined,
    validationFilter: PropertyFilter.Empty,
    readOnly: readonly,
    onFormatChanged: (
      unit: Unit.Unit<Quantity.Quantity>,
      decimalCount: number
    ) =>
      dispatch(
        Action.onFormatChanged(fieldGroup, item.name, unit, decimalCount)
      ),
    onFormatCleared: () => {
      dispatch(Action.onFormatCleared(fieldGroup, item.name));
    }
  };
}
