import React from "react";
import styled from "../../styled-components";

// tslint:disable-next-line
const arrowUp = require("ui-elements/assets/button/arrow-down-blue.svg");
// tslint:disable-next-line
const arrowDown = require("ui-elements/assets/button/arrow-up-blue.svg");
// tslint:disable-next-line
const arrowRight = require("ui-elements/assets/button/arrow-right-blue.svg");

type MenuButtonWrapperProps = {
  readonly isActive?: boolean;
};

const MenuButtonWrapper = styled.div<MenuButtonWrapperProps>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  /* color: #8f9bb3; */
  font-weight: 300;
  letter-spacing: 0;
  background: #ffffff;
  margin-bottom: 0.1em;
  line-height: 17px;
  height: 24px;

  > label {
    color: ${props => (props.isActive ? "#4bccff" : "#525252")};
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 7px;
    cursor: pointer;

    &:hover {
      /* color: #4bccff; */
    }
  }

  img {
    text-align: center;
    margin-right: 7px;
    /* height: 14px; */
    width: 14px;
    cursor: pointer;
  }
`;

export function MenuButton(props: {
  readonly onClick?: () => void;
  readonly hasMenu: boolean;
  readonly show?: boolean;
  readonly isOpen?: boolean;
  readonly children: React.ReactNode;
  readonly isActive?: boolean;
}): JSX.Element | null {
  const { onClick, isActive, hasMenu, children, isOpen, show = true } = props;

  if (!show) {
    return null;
  }

  function getImageSrc() {
    if (!hasMenu) {
      return arrowRight;
    }
    return isOpen ? arrowDown : arrowUp;
  }

  function onClickHandler(event: React.MouseEvent<HTMLElement>) {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  }
  return (
    <MenuButtonWrapper isActive={isActive} onClick={onClickHandler}>
      <label>{children}</label>
      {<img src={getImageSrc()}></img>}
    </MenuButtonWrapper>
  );
}
