import * as Types from "./types";
import { buildNodesAndEdges } from "./build-nodes-and-edges";
import { assignSubgraphIds } from "./assign-sub-graphs";
import { applyTransformsAvoidingOverlapsByEdgeExtension } from "./apply-transforms-avoiding-overlaps-by-edge-extension";
import { arrangeSubGraphs } from "./arrange-sub-graphs";
import * as AbstractImage from "abstract-image";
import * as Helper from "./helper";
import * as Calculations from "@munters/calculations";
import { Quantity } from "@genesys/uom";
import { PropertyValue } from "@genesys/property";
import {
  EditBlueRaw,
  EditGreyRaw,
  AccessoriesBlueRaw,
  AccessoriesGreyRaw,
  MenuBlueRaw,
  MenuGreyRaw,
  defaultFontFamily
} from "@genesys/ui-elements";

export function buildDiagram(
  showErrors: boolean,
  diagramType: Types.DiagramType,
  includeLabel: boolean,
  products: ReadonlyArray<Types.Product>,
  system: Types.System,
  translatePropertyValue: (
    productId: string,
    propertyName: string,
    propertyValue: number
  ) => string,
  translateText: (key: string) => string,
  curlyTranslate: (key: string) => string,
  getAmountFormatString: (
    fieldGroup: string,
    fieldName: string,
    quantity: Quantity.Quantity,
    property: PropertyValue.AmountPropertyValue
  ) => string,
  expandSize: number
): Types.Diagram {
  let diagram = buildNodesAndEdges(
    products,
    system,
    showErrors,
    diagramType,
    includeLabel,
    translatePropertyValue,
    translateText,
    curlyTranslate,
    getAmountFormatString
  );
  diagram = assignSubgraphIds(diagram);
  diagram = applyTransformsAvoidingOverlapsByEdgeExtension(diagram);
  diagram = arrangeSubGraphs(diagram, expandSize);

  return diagram;
}

const selectedColor = AbstractImage.fromString("#FFFFFFFF")!;

export function buildAbstractImage(
  diagram: Types.Diagram,
  selections: ReadonlyArray<Types.Selection>,
  positionLabels: ReadonlyArray<Types.PositionLabel>,
  openPositions: ReadonlyArray<Types.Position>,
  symbols: ReadonlyArray<Types.SymbolDefinition>,
  expandSize: number,
  hoverState?: Types.HoverState,
  hoverText?: string,
  selectedAirPosition?: string
): AbstractImage.AbstractImage {
  const bounds = Calculations.Math.BoundingRect.expand(
    expandSize,
    diagram.nodes
      .map(n => Helper.getNodeBounds(n))
      .reduce(
        (a, b) => Calculations.Math.BoundingRect.unionBoundingRect(a, b),
        Calculations.Math.BoundingRect.empty
      )
  );
  const width = bounds.halfSize.x * 2;
  const height = bounds.halfSize.y * 2;

  let renderedPorts = Array<Types.Port>();
  const symbolsAbstractImageComponents = Array<AbstractImage.Component>();
  const selectedSymbolsAbstractImageComponents =
    Array<AbstractImage.Component>();
  const selectionComponents = Array<AbstractImage.Component>();
  const connectionLines = Array<AbstractImage.Line>();
  const connectionComponents = Array<AbstractImage.Component>();
  const openComponents = Array<AbstractImage.Component>();
  const textComponents = Array<AbstractImage.Text>();
  const labelComponents = Array<AbstractImage.Rectangle | AbstractImage.Text>();

  for (const node of diagram.nodes) {
    const selectedNode = selections.some(
      s => s.componentId === node.componentId
    );
    for (const symbol of node.symbols.concat().sort((a, b) => a.z - b.z)) {
      const symbolDef = symbols.find(s => s.symbolId === symbol.symbolId);
      if (symbolDef === undefined) {
        console.log(
          "Could not find symbol image for symbol id: " + symbol.symbolId
        );
        continue;
      }
      const transform = Calculations.Math.Matrix2.multiply(
        node.transform,
        symbol.transform
      );
      const size = Calculations.Math.Matrix2.applyDirection(
        Calculations.Math.Vector2.vec2Create(
          symbol.size.x / 2,
          symbol.size.y / 2
        ),
        transform
      );
      const symbolBounds = Calculations.Math.BoundingRect.create(
        Calculations.Math.Matrix2.apply(
          Calculations.Math.Vector2.vec2Zero,
          transform
        ),
        Calculations.Math.Vector2.vec2Create(Math.abs(size.x), Math.abs(size.y))
      );

      if (
        diagram.type === "FlowDiagram" ||
        diagram.type === "UnitConfiguration"
      ) {
        const image = AbstractImage.createRectangle(
          AbstractImage.createPoint(
            symbolBounds.position.x - symbolBounds.halfSize.x - 10,
            symbolBounds.position.y - symbolBounds.halfSize.y - 10
          ),
          AbstractImage.createPoint(
            symbolBounds.position.x + symbolBounds.halfSize.x + 10,
            symbolBounds.position.y + symbolBounds.halfSize.y + 10
          ),
          AbstractImage.transparent,
          1,
          AbstractImage.transparent,
          node.componentId
        );
        if (selectedNode) {
          selectedSymbolsAbstractImageComponents.push(image);
        } else {
          symbolsAbstractImageComponents.push(image);
        }
      }

      const m = !symbol.noRotation
        ? Calculations.Math.Matrix2.multiply(
            transform,
            Calculations.Math.Matrix2.multiply(
              Calculations.Math.Matrix2.translate(
                -symbol.size.x / 2,
                -symbol.size.y / 2
              ),
              Calculations.Math.Matrix2.scale(
                symbol.size.x / symbolDef.width,
                symbol.size.y / symbolDef.height
              )
            )
          )
        : Calculations.Math.Matrix2.multiply(
            Calculations.Math.Matrix2.translate(
              Calculations.Math.BoundingRect.getLeft(symbolBounds),
              Calculations.Math.BoundingRect.getTop(symbolBounds)
            ),
            Calculations.Math.Matrix2.scale(
              symbol.size.x / symbolDef.width,
              symbol.size.y / symbolDef.height
            )
          );

      const trimmedSvg = symbolDef.svg.replace(/<\?xml.+?\?>/g, "").trim();

      const group =
        `<g transform="matrix(${m.m11}, ${m.m21}, ${m.m12}, ${m.m22}, ${m.x}, ${m.y})">
        <title>${node.componentLabel}</title>` +
        trimmedSvg +
        `</g>`;

      const utf8Symbol = unescape(group);

      const data = utf8Symbol.split("").reduce((a, _b, ix) => {
        a.push(utf8Symbol.charCodeAt(ix));
        return a;
      }, [] as Array<number>);
      const binaryImage = AbstractImage.createBinaryImage(
        { x: 0, y: 0 },
        { x: symbol.transform.x, y: symbol.transform.y },
        "svg",
        new Uint8Array(data),
        node.componentId
      );
      if (selectedNode) {
        selectedSymbolsAbstractImageComponents.push(binaryImage);
      } else {
        symbolsAbstractImageComponents.push(binaryImage);
      }
    }

    if (node.label || node.labelAbove) {
      const nodeBounds = Calculations.Math.BoundingRect.expand(
        expandSize / 4,
        Helper.getNodeBounds(node)
      );
      const textWidth =
        Calculations.Math.BoundingRect.getWidth(nodeBounds) + 40;
      const textHeight = 14;
      const textTop = Calculations.Math.BoundingRect.equals(
        nodeBounds,
        Calculations.Math.BoundingRect.empty
      )
        ? node.transform.y
        : Calculations.Math.BoundingRect.getBottom(nodeBounds);

      const textBottom = Calculations.Math.BoundingRect.equals(
        nodeBounds,
        Calculations.Math.BoundingRect.empty
      )
        ? node.transform.y
        : Calculations.Math.BoundingRect.getTop(nodeBounds);
      const textLeft = nodeBounds.position.x - textWidth * 0.5;

      const labelBackground = AbstractImage.createRectangle(
        AbstractImage.createPoint(textLeft, textTop),
        AbstractImage.createPoint(textLeft + textWidth, textTop + textHeight),
        AbstractImage.transparent,
        1,
        AbstractImage.white
      );

      if (!selectedNode) {
        if (node.label) {
          const label = AbstractImage.createText(
            AbstractImage.createPoint(nodeBounds.position.x, textTop),
            node.label,
            "Helvetica",
            10,
            AbstractImage.black,
            "normal",
            0,
            "center",
            "uniform",
            "uniform",
            0,
            AbstractImage.black
          );

          labelComponents.push(labelBackground, label);
        }

        if (node.labelAbove) {
          const labelAbove = AbstractImage.createText(
            AbstractImage.createPoint(nodeBounds.position.x, textBottom),
            node.labelAbove,
            "Arial",
            8,
            AbstractImage.black,
            "normal",
            0,
            "center",
            "uniform",
            "uniform",
            0,
            AbstractImage.black
          );

          if (!labelComponents.includes(labelBackground)) {
            labelComponents.push(labelBackground);
          }

          labelComponents.push(labelAbove);
        }
      }
    }

    for (const port of node.ports) {
      // tslint:disable-next-line
      if (~renderedPorts.indexOf(port)) {
        continue;
      }

      const portInfo = Helper.getPortsAndPositions(diagram, node, port);

      if (portInfo.port1 !== undefined) {
        renderedPorts.push(portInfo.port1);
      }
      if (portInfo.port2 !== undefined) {
        renderedPorts.push(portInfo.port2);
      }

      connectionLines.push(
        AbstractImage.createLine(
          { x: portInfo.startPoint.x, y: portInfo.startPoint.y },
          { x: portInfo.endPoint.x, y: portInfo.endPoint.y },
          AbstractImage.black,
          2
        )
      );

      const mid = Calculations.Math.Vector2.vec2Scale(
        Calculations.Math.Vector2.vec2Add(
          portInfo.startPoint,
          portInfo.endPoint
        ),
        0.5
      );

      const label = positionLabels.find(
        pl =>
          isPortEquals(pl.position.outlet, portInfo.port1) &&
          isPortEquals(pl.position.inlet, portInfo.port2)
      );
      if (label !== undefined) {
        const isSelected = selectedAirPosition === label.label;
        connectionComponents.push(
          AbstractImage.createEllipse(
            { x: mid.x - 15, y: mid.y - 15 },
            { x: mid.x + 15, y: mid.y + 15 },
            AbstractImage.black,
            1,
            isSelected ? AbstractImage.lightBlue : AbstractImage.white
          )
        );

        connectionComponents.push(
          AbstractImage.createText(
            { x: mid.x, y: mid.y - 2.5 },
            label.label,
            "Helvetica",
            18,
            AbstractImage.black,
            "normal",
            0,
            "center",
            "uniform",
            "uniform",
            0,
            AbstractImage.black
          )
        );
        // This one is to make the airposition clickable
        connectionComponents.push(
          AbstractImage.createEllipse(
            { x: mid.x - 15, y: mid.y - 15 },
            { x: mid.x + 15, y: mid.y + 15 },
            AbstractImage.black,
            1,
            AbstractImage.transparent,
            label.label
          )
        );
      }

      const openPosition = openPositions.find(
        p =>
          isPortEquals(p.outlet, portInfo.port1) ||
          isPortEquals(p.inlet, portInfo.port2)
      );
      if (openPosition !== undefined) {
        openComponents.push(
          ...createOpenPosition(
            mid,
            `openposition.${
              openPosition.inlet && openPosition.inlet.componentSectionId
            }.${openPosition.outlet && openPosition.outlet.componentSectionId}`
          )
        );
      }
    }

    for (const text of node.texts) {
      const position = text.absolutePosition
        ? Calculations.Math.Vector2.vec2Add(
            Calculations.Math.Matrix2.apply(
              Calculations.Math.Vector2.vec2Zero,
              node.transform
            ),
            text.position
          )
        : Calculations.Math.Matrix2.apply(
            Calculations.Math.Vector2.vec2Zero,
            Calculations.Math.Matrix2.multiply(
              node.transform,
              Calculations.Math.Matrix2.composite(
                text.rotation,
                false,
                true,
                text.position.x,
                text.position.y
              )
            )
          );

      //tslint:disable-next-line
      !selectedNode &&
        textComponents.push(
          AbstractImage.createText(
            position,
            text.text,
            "Helvetica",
            text.fontSize,
            AbstractImage.black,
            "normal",
            text.rotation,
            "center",
            "uniform",
            "uniform",
            0,
            AbstractImage.transparent
          )
        );
    }
    if (selectedNode) {
      const bounds = Helper.getNodeBounds(node);
      selectionComponents.push(
        ...createSelection(
          bounds.position.y - bounds.halfSize.y - 40,
          bounds.position.x + bounds.halfSize.x + 40,
          bounds.position.y + bounds.halfSize.y + 40,
          bounds.position.x - bounds.halfSize.x - 40,
          node.componentId,
          node.componentLabel,
          hoverState || "no-hover",
          hoverText || ""
        )
      );
    }
  }

  // for clickevents
  const invisibleBackground = AbstractImage.createRectangle(
    AbstractImage.createPoint(0, 0),
    AbstractImage.createPoint(width, height),
    AbstractImage.transparent,
    0,
    AbstractImage.transparent
  );

  const image = AbstractImage.createAbstractImage(
    {
      x: 0,
      y: 0
    },
    {
      width: width,
      height: height
    },
    AbstractImage.white,
    [
      invisibleBackground,
      ...connectionLines,
      ...symbolsAbstractImageComponents,
      ...connectionComponents,
      ...textComponents,
      ...labelComponents,
      ...selectionComponents,
      ...selectedSymbolsAbstractImageComponents,
      ...openComponents
    ]
  );

  return image;
}

function isPortEquals(
  portA: Types.Port | undefined,
  portB: Types.Port | undefined
): boolean {
  if (portA === undefined || portB === undefined) {
    return portA === portB;
  }

  return portA.componentSectionId === portB.componentSectionId;
}

function createSelection(
  top: number,
  right: number,
  bottom: number,
  left: number,
  componentId: string,
  componentLabel: string,
  hoverState: Types.HoverState,
  hoverText: string
): ReadonlyArray<AbstractImage.Component> {
  const menuId = "menu." + componentId;
  const editId = "edit." + componentId;
  const accessoryId = "accessory." + componentId;
  const selectionBorder = 0.5;
  const headerFontSize = 12;
  const hoverFontSize = 8;
  const diameter = 16;

  const selectionBackground = AbstractImage.createRectangle(
    AbstractImage.createPoint(left, top),
    AbstractImage.createPoint(right, bottom),
    AbstractImage.fromString("#FF3D3D3D")!,
    selectionBorder,
    selectedColor
  );

  const menuHeight = 24;

  const actionMenu = AbstractImage.createRectangle(
    AbstractImage.createPoint(
      left + selectionBorder,
      bottom - menuHeight - selectionBorder
    ),
    AbstractImage.createPoint(
      right - selectionBorder,
      bottom - selectionBorder
    ),
    AbstractImage.black,
    0,
    AbstractImage.fromString("#FFF7F9FC")!
  );

  const selectionOverlay = AbstractImage.createRectangle(
    AbstractImage.createPoint(left, top),
    AbstractImage.createPoint(right, bottom),
    AbstractImage.transparent,
    0,
    AbstractImage.transparent,
    componentId
  );

  const createActionIcon = (x: number, y: number, isHover: boolean) => {
    const iconImport = isHover ? MenuGreyRaw : MenuBlueRaw;
    const icon = `<g transform="translate(${x - diameter / 2} ${
      y - diameter / 2
    })">${atob(iconImport.split(",")[1])}</g>`;

    const iconImage = AbstractImage.createBinaryImage(
      AbstractImage.createPoint(x - diameter / 2, y - diameter / 2),
      AbstractImage.createPoint(x + diameter / 2, y + diameter / 2),
      "svg",
      Buffer.from(icon)
    );

    return [
      iconImage,
      AbstractImage.createEllipse(
        AbstractImage.createPoint(x - diameter / 2, y - diameter / 2),
        AbstractImage.createPoint(x + diameter / 2, y + diameter / 2),
        AbstractImage.transparent,
        0,
        AbstractImage.transparent,
        menuId
      )
    ];
  };

  const createEditActionIcon = (x: number, y: number, isHover: boolean) => {
    const iconImport = isHover ? EditGreyRaw : EditBlueRaw;
    const icon = `<g transform="translate(${x - diameter / 2} ${
      y - diameter / 2
    })">${atob(iconImport.split(",")[1])}</g>`;

    const iconImage = AbstractImage.createBinaryImage(
      AbstractImage.createPoint(x - diameter / 2, y - diameter / 2),
      AbstractImage.createPoint(x + diameter / 2, y + diameter / 2),
      "svg",
      Buffer.from(icon)
    );

    return [
      iconImage,
      AbstractImage.createEllipse(
        AbstractImage.createPoint(x - diameter / 2, y - diameter / 2),
        AbstractImage.createPoint(x + diameter / 2, y + diameter / 2),
        AbstractImage.transparent,
        0,
        AbstractImage.transparent,
        editId
      )
    ];
  };

  const createAccessoryActionIcon = (
    x: number,
    y: number,
    isHover: boolean
  ) => {
    const iconImport = isHover ? AccessoriesGreyRaw : AccessoriesBlueRaw;

    const icon = `<g transform="translate(${x - diameter / 2} ${
      y - diameter / 2
    })">${atob(iconImport.split(",")[1])}</g>`;

    const iconImage = AbstractImage.createBinaryImage(
      AbstractImage.createPoint(x - diameter / 2, y - diameter / 2),
      AbstractImage.createPoint(x + diameter / 2, y + diameter / 2),
      "svg",
      Buffer.from(icon)
    );

    return [
      iconImage,
      AbstractImage.createEllipse(
        AbstractImage.createPoint(x - diameter / 2, y - diameter / 2),
        AbstractImage.createPoint(x + diameter / 2, y + diameter / 2),
        AbstractImage.transparent,
        0,
        AbstractImage.transparent,
        accessoryId
      )
    ];
  };

  const actionIcon = createActionIcon(
    right - diameter / 2 - 8,
    bottom - menuHeight / 2 - selectionBorder,
    hoverState === "menu"
  );

  const editActionIcon = createEditActionIcon(
    left + diameter / 2 + 8,
    bottom - menuHeight / 2 - selectionBorder,
    hoverState === "edit"
  );

  const accessoryActionIcon = createAccessoryActionIcon(
    left + (diameter / 2 + 8) * 2.5,
    bottom - menuHeight / 2 - selectionBorder,
    hoverState === "accessory"
  );

  const headerText = AbstractImage.createText(
    AbstractImage.createPoint(
      left + selectionBorder + (right - left) / 2,
      top + selectionBorder + headerFontSize
    ),
    componentLabel,
    defaultFontFamily,
    headerFontSize,
    AbstractImage.fromString("#FF646F86")!,
    "normal",
    0,
    "center",
    "uniform",
    "uniform",
    0,
    AbstractImage.fromString("#FF97A1B8")!
  );

  const hoverTextXPosition =
    hoverState === "edit"
      ? left + diameter / 2 + 8
      : hoverState === "accessory"
      ? left + (diameter / 2 + 8) * 2.5
      : hoverState === "menu"
      ? right - diameter / 2 - 8
      : 0;

  const abstractHoverText = AbstractImage.createText(
    AbstractImage.createPoint(hoverTextXPosition, bottom - 34),
    hoverText,
    defaultFontFamily,
    hoverFontSize,
    AbstractImage.fromString("#FFFFFFFF")!,
    "normal",
    0,
    "center",
    "uniform",
    "uniform",
    0,
    AbstractImage.fromString("#FF97A1B8")!
  );

  const hoverTextWidth = hoverText.length * 5;

  const hoverTextRect = AbstractImage.createRectangle(
    AbstractImage.createPoint(
      hoverTextXPosition - hoverTextWidth / 2,
      bottom - 40
    ),
    AbstractImage.createPoint(
      hoverTextXPosition + hoverTextWidth / 2,
      bottom - 26
    ),
    hoverState !== "no-hover"
      ? AbstractImage.fromString("#FF646F86")!
      : AbstractImage.transparent,
    0,
    hoverState !== "no-hover"
      ? AbstractImage.fromString("#FF646F86")!
      : AbstractImage.transparent
  );

  const hoverTextPoly = AbstractImage.createPolygon(
    [
      { x: hoverTextXPosition, y: bottom - 22 },
      { x: hoverTextXPosition - 4, y: bottom - 26 },
      { x: hoverTextXPosition + 4, y: bottom - 26 }
    ],
    hoverState !== "no-hover"
      ? AbstractImage.fromString("#FF646F86")!
      : AbstractImage.transparent,
    0,
    hoverState !== "no-hover"
      ? AbstractImage.fromString("#FF646F86")!
      : AbstractImage.transparent
  );

  return [
    selectionBackground,
    headerText,
    hoverTextRect,
    abstractHoverText,
    actionMenu,
    hoverTextPoly,
    selectionOverlay,
    ...actionIcon,
    ...editActionIcon,
    ...accessoryActionIcon
  ];
}

function createOpenPosition(
  center: AbstractImage.Point,
  id: string
): ReadonlyArray<AbstractImage.Component> {
  const openBackground = AbstractImage.createRectangle(
    AbstractImage.createPoint(center.x - 20, center.y - 20),
    AbstractImage.createPoint(center.x + 20, center.y + 20),
    AbstractImage.black,
    1,
    AbstractImage.fromString("#FF8CFF8C")!,
    id
  );

  return [openBackground];
}

// tslint:disable-next-line
