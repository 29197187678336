import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 16px 16px 16px;
`;

export const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 620px;

  p {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.2rem;
    text-align: center;
    color: #66a;
  }
`;

export const InformationContianer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 4px;
  background-color: ${PrimaryColors.muntersBlue};
  img {
    margin-right: 8px;
  }
`;

export const SelectorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 18px;
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CostTypeTable = styled.table`
  padding-top: 6px;

  color: #8f9bb3;
  font-size: 13px;
  font-weight: 500;
`;

export const WideTd = styled.td`
  width: 200px;
  height: 30px;
`;

export const NarrowTd = styled.td`
  width: 130px;
  height: 30px;
  padding-right: 30px;
`;
