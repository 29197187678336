import styled from "styled-components";

export const Container = styled.div`
  width: 380px;

  a {
    display: block;
    margin-bottom: 10px;
    color: inherit;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HeaderLabel = styled.label`
  color: #16181e;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
`;

export const Content = styled.div``;

export const StyledP = styled.p`
  /* color: #646f86; */
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    display: inline-block;
    color: #2b89ed;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const StyledDiv = styled.div`
  max-height: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NewsItem = styled.div`
  margin-bottom: 10px;
`;
