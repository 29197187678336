import React from "react";
import styled from "../../styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";

export type ButtonType =
  | "PrimaryBlue"
  | "SecondaryBlue"
  | "PrimaryGreen"
  | "SecondaryGreen";

export type Size = "Large" | "Small";

type ExtraProps = {
  readonly buttonType: ButtonType;
  readonly size: Size;
  readonly icon?: (props: {
    readonly width: number;
    readonly height: number;
  }) => JSX.Element;
};

// tslint:disable-next-line
// StyledButton Component
export const StyledButton = styled.button<ExtraProps>`
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  padding: ${props => getPadding(props.size)};

  ${props => !props.disabled && "cursor: pointer;"}

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    text-decoration: ${props => (!props.disabled ? "none" : "underline")};
  }

  img {
    margin-right: 8px;
  }

  // Apply border and color styles based on the button type
  ${props => getButtonStyles(props)}
`;

// Centralized style configuration for different button types
const buttonStyleConfig: Record<
  ButtonType,
  {
    readonly border: string;
    readonly color: string;
  }
> = {
  SecondaryBlue: {
    border: `
      border: 1px solid #2B89ED;
      &:hover { border: 1px solid #1D61CF; }
      &:disabled { border: 1px solid rgba(0, 173, 242, 0.3); }
    `,
    color: `
      background-color: white;
      color: #2B89ED;
      &:hover { color: #1D61CF; }
    `
  },
  SecondaryGreen: {
    border: `
      border: 1px solid #00BAFF;
      &:hover { border: 1px solid #66D9FB; }
      &:disabled { border: 1px solid #B1B1B1 }
    `,
    color: `
      color: #00BAFF;
      background-color: white;
      &:hover { color: #66D9FB;; }
      &:disabled { background-color: 1px solid #B1B1B1 }
    `
  },
  PrimaryBlue: {
    border: ``,
    color: `
      background-color: #2b89ed;
      color: white;
      &:hover { background-color: #1D61CF; }
    `
  },
  PrimaryGreen: {
    border: ``,
    color: `
      background-color: #00BAFF;
      color: white;
      &:hover { background-color: #66D9FB; }
    `
  }
};

// Helper function to retrieve styles based on button type
function getButtonStyles(props: ExtraProps): string {
  const styleConfig = buttonStyleConfig[props.buttonType];
  if (!styleConfig) {
    return ""; // Return empty string if type is not found
  }
  return `
    ${styleConfig.border}
    ${styleConfig.color}
  `;
}

// Helper function for padding based on button size
function getPadding(size: Size): string {
  switch (size) {
    case "Large":
      return "12px 28px";
    case "Small":
      return "7px 12px";
    default:
      return exhaustiveCheck(size, true);
  }
}

// Helper function to get styles from the config object
function getStyleFromConfig(
  props: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
  styleType: keyof typeof buttonStyleConfig["PrimaryBlue"]
): string {
  const styles = buttonStyleConfig[props.buttonType];
  return styles ? styles[styleType] : "";
}

// Get border styles
export function getBorderStyles(
  props: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>
): string {
  return getStyleFromConfig(props, "border");
}

// Get color styles
export function getColorStyles(
  props: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>
): string {
  return getStyleFromConfig(props, "color");
}

export function StandardButton({
  children,
  icon: TheIcon,
  onClick,
  ...buttonProps
}: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <StyledButton
      onClick={e => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }

        // tslint:disable-next-line
      }}
      {...buttonProps}
    >
      {TheIcon && <TheIcon width={20} height={20} />}
      {children}
    </StyledButton>
  );
}
