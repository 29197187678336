import React from "react";
import styled from "../../../styled-components";

import Skeleton from "@mui/material/Skeleton";
import { S1 } from "../../../atoms/typography";

interface Props {
  readonly hasImage: boolean;
}

const Container = styled.div`
  height: ${(props: Props) => (props.hasImage ? "368px" : "140px")};
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  width: 381px;
  padding-top: 12.5px;

  :hover {
    border: 1px solid #3d3d3d;
  }
`;

const SkeletonContainer = styled.div`
  height: 140px;
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  width: 381px;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 12.5px;
`;

const Title = styled(S1)`
  margin-bottom: 8px;
  padding-left: 30px;
  padding-right: 10px;
  font-size: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImgContainer = styled.div`
  height: 200px;
  width: 340px;
  margin: auto;
  margin-bottom: 12px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const StyledP = styled.p`
  color: #646f86;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Content = styled.div`
  overflow-y: hidden;
  height: 106px;
  padding-left: 30px;
  padding-right: 10px;
`;

export function NewInHelpDeskCard(props: {
  readonly imageSrc?: string;
  readonly title?: string;
  readonly contents?: React.ReactNode;
}) {
  const hasImage =
    props.imageSrc !== undefined && !!props.imageSrc.replace(/\s/g, "").length;
  if (props.title === undefined || props.contents === undefined) {
    return (
      <SkeletonContainer>
        <Skeleton
          style={{ marginBottom: "15px" }}
          variant="rectangular"
          width={"70%"}
          height={20}
        />
        <StyledP>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </StyledP>
      </SkeletonContainer>
    );
  }
  return (
    <Container hasImage={hasImage}>
      {hasImage && (
        <ImgContainer>
          <img src={props.imageSrc} />
        </ImgContainer>
      )}
      <Title color="primary">{props.title}</Title>
      <Content>{props.contents}</Content>
    </Container>
  );
}
