import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const TopContainer = styled.div`
  height: 111px;
  border-top: 2px solid rgba(221, 228, 240, 0.3);
  border-bottom: 2px solid rgba(221, 228, 240, 0.3);
  display: flex;
  justify-content: space-between;
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 18px 24px;
  h1 {
    margin: 0 15px;
    /* color: ${PrimaryColors.muntersBlue}; */
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  align-items: stretch;
  height: 820px;
`;

export const ResultsRoot = styled.div`
  width: 1205px;
  margin-left: 16px;
`;

export const RevisionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 20px;
    /* height: 30px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    :hover {
      border-radius: 15px;
      background: ${PrimaryColors.lightBlue};
    }
  }
`;

export const StatusContainer = styled.div`
  * {
    margin-top: 4px;
    margin-left: 10px;
  }
`;
