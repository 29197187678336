import * as React from "react";
import {
  Container,
  Body,
  Header,
  LabelForRecent,
  RightButtonsContainer,
  SystemItem
} from "./elements";
import { InlineButton } from "@genesys/ui-elements";

import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as RecentSystemCard from "../../../recent-system-card";
import { Previous, Next } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../../index";
import * as GraphQlTypes from "../../../graphql-types";

export const RecentSystemsView = ({
  sharedState,
  productData,
  dispatch,
  recentSystemCardState
}: {
  readonly sharedState: SharedState.State;
  readonly productData: GraphQlTypes.DashboardProductQuery | undefined;
  readonly dispatch: Dispatch<Action>;
  readonly recentSystemCardState: {
    readonly [cardIndex: string]: RecentSystemCard.State;
  };
}) => {
  const outerDivRef = React.useRef<HTMLDivElement>(null);

  const [currentScrollLeftValue, setScrollLeft] = React.useState(0);
  const [status, setStatus] = React.useState({
    isPressed: false,
    goRight: false
  });

  const pixelsToMove = 15;

  const scroll = (goRight?: boolean) => {
    const div = outerDivRef.current!;
    const offsetWidth = div.offsetWidth;
    let newScrollLeftValue = div.scrollLeft;

    const newComputedDistance = goRight
      ? newScrollLeftValue + pixelsToMove
      : newScrollLeftValue - pixelsToMove;

    if (checkBoundaries(newComputedDistance, offsetWidth)) {
      newScrollLeftValue = newComputedDistance;
      div.scrollLeft = newScrollLeftValue;
    } else {
      newScrollLeftValue = newComputedDistance > offsetWidth ? offsetWidth : 0;
    }

    setScrollLeft(newScrollLeftValue);
  };

  React.useEffect(() => {
    if (status.isPressed) {
      scroll(status.goRight);
    }
  }, [currentScrollLeftValue]);

  const nextIsDisabled =
    !outerDivRef.current ||
    outerDivRef.current.scrollWidth <= outerDivRef.current.clientWidth ||
    currentScrollLeftValue >= outerDivRef.current.offsetWidth;

  const backIsDisabled = !outerDivRef.current || currentScrollLeftValue <= 0;

  function capitalizeFirstLetter(str: string) {
    if (!str) {
      return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <Container>
      <Header>
        <LabelForRecent>
          {capitalizeFirstLetter(
            sharedState.translate(LanguageTexts.recentsystemsheader())
          )}
        </LabelForRecent>
        <RightButtonsContainer>
          <InlineButton>
            <a href="/system-manager">
              <label>
                {sharedState.translate(LanguageTexts.viewAll()).toUpperCase()}
              </label>
            </a>
          </InlineButton>
          <span>
            <Previous
              disabled={backIsDisabled}
              onMouseUp={() => {
                setStatus({
                  ...status,
                  isPressed: false
                });
              }}
              onClick={() => {
                /** for cursor pointer */
              }}
              onMouseDown={() => {
                setStatus({
                  ...status,
                  isPressed: true,
                  goRight: false
                });
                scroll();
              }}
            />
            <Next
              onMouseUp={() => {
                setStatus({
                  ...status,
                  isPressed: false
                });
              }}
              disabled={nextIsDisabled}
              onClick={() => {
                /** for cursor pointer */
              }}
              onMouseDown={() => {
                setStatus({
                  ...status,
                  isPressed: true,
                  goRight: true
                });
                scroll(true);
              }}
            />
          </span>
        </RightButtonsContainer>
      </Header>
      <Body ref={outerDivRef as any}>
        {sharedState.user.lastOpenedSystems.map((recentSystem, i) => {
          if (recentSystem.type === "loading") {
            return (
              <SystemItem key={`${recentSystem.id}`}>
                <RecentSystemCard.View
                  dispatch={Dispatch.map(
                    action =>
                      Action.dispatchRecentSystemCard(recentSystem.id, action),
                    dispatch
                  )}
                  state={recentSystemCardState[recentSystem.id]}
                  sharedState={sharedState}
                  recentSystem={recentSystem}
                  plenumSizeProperty={"notneeded"}
                  showErrorDetails={() => ({})}
                />
              </SystemItem>
            );
          }

          if (recentSystem.type === "error") {
            return (
              <SystemItem key={`${i}${recentSystem.id}`}>
                <RecentSystemCard.View
                  state={recentSystemCardState[recentSystem.id]!}
                  sharedState={sharedState}
                  dispatch={Dispatch.map(
                    action =>
                      Action.dispatchRecentSystemCard(recentSystem.id, action),
                    dispatch
                  )}
                  recentSystem={recentSystem}
                  plenumSizeProperty={"notneeded"}
                  showErrorDetails={() =>
                    dispatch(
                      Action.showSystemErrorDetails({
                        systemId: recentSystem.id,
                        message: recentSystem.message
                      })
                    )
                  }
                />
              </SystemItem>
            );
          }

          return (
            productData &&
            recentSystemCardState[recentSystem.system.id] && (
              <SystemItem key={`${i}${recentSystem.system.id}`}>
                <RecentSystemCard.View
                  state={recentSystemCardState[recentSystem.system.id]!}
                  sharedState={sharedState}
                  dispatch={Dispatch.map(
                    action =>
                      Action.dispatchRecentSystemCard(
                        recentSystem.system.id,
                        action
                      ),
                    dispatch
                  )}
                  recentSystem={recentSystem}
                  plenumSizeProperty={
                    productData.product.systemTypes.find(
                      st => st.id === recentSystem.system.systemTypeId
                    )!.plenumSizeProperty
                  }
                  showErrorDetails={() => ({})}
                />
              </SystemItem>
            )
          );
        })}
      </Body>
    </Container>
  );
};

function checkBoundaries(scrollLeft: number, offsetWidth: number) {
  return scrollLeft >= 0 && scrollLeft <= offsetWidth;
}
