import React from "react";
import styled from "../../styled-components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { defaultFontFamily } from "../../atoms/typography";

type StylingProps = {
  readonly width?: number;
  readonly height?: number;
  readonly fontSize?: number;
  readonly backgroundColor?: string;
};

const BootstrapInput = styled(InputBase)<StylingProps>(
  ({ width, height, fontSize, backgroundColor }) => ({
    "& .MuiInputBase-input": {
      minWidth: width === undefined ? "370px" : width + "px",
      height: height === undefined ? "50px" : height + "px",
      lineHeight: height === undefined ? "50px" : height + "px",
      fontSize: fontSize === undefined ? "18px" : fontSize + "px",
      backgroundColor:
        backgroundColor === undefined ? "initial" : backgroundColor,
      border: "1px solid #d2d5d8",
      padding: "0 20px",
      borderRadius: "0",
      fontFamily: defaultFontFamily
    }
  })
);

export function GenesysSelect({
  onChange,
  value,
  options,
  disabled,
  width,
  height,
  fontSize,
  backgroundColor
}: {
  readonly options: ReadonlyArray<{
    readonly value: string | number;
    readonly title: string;
  }>;
} & Pick<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  "onChange" | "value" | "disabled" | "className"
> &
  StylingProps): JSX.Element {
  return (
    <div>
      <Select
        disabled={disabled}
        value={value}
        onChange={(e, _c) =>
          onChange !== undefined ? onChange(e as any) : undefined
        }
        input={
          <BootstrapInput
            width={width}
            height={height}
            fontSize={fontSize}
            backgroundColor={backgroundColor}
          />
        }
      >
        {options.map(o => (
          <MenuItem
            key={o.value}
            value={o.value}
            sx={{ fontFamily: defaultFontFamily }}
          >
            {o.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
